import React from 'react';

interface props {
  color: string;
  size: string;
}

export const HomeLogo: React.FC<props> = ({ color, size }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 100 100' version='1.1'>
      <g className='layer'>
        <path
          d='m85.29,68.95l-4,-10.06a3,3 0 0 0 -4.91,-1.01l-7.19,7.19a3,3 0 0 0 1.38,5.02l2.63,0.68a24.17,24.17 0 0 1 -19.7,14.05l0,-37.82l9,0a3,3 0 1 0 0,-6l-9,0l0,-10.47a11,11 0 1 0 -6,0l0,10.47l-9,0a3,3 0 1 0 0,6l9,0l0,37.82a24.17,24.17 0 0 1 -19.7,-14.05l2.63,-0.68a3,3 0 0 0 1.38,-5.02l-7.19,-7.19a3,3 0 0 0 -4.91,1.01l-4,10.06a3,3 0 0 0 3.54,4.01l2.61,-0.67c4.57,11.21 15.38,18.71 28.64,18.71s24.07,-7.5 28.64,-18.71l2.61,0.67a3,3 0 0 0 3.54,-4.01zm-39.79,-48.95a5,5 0 1 1 5,5a5,5 0 0 1 -5,-5z'
          id='svg_1'
          fill={color}
        />
      </g>
    </svg>
  );
};

export const AboutLogo: React.FC<props> = ({ color, size }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 100 100' version='1.1'>
      <g className='layer'>
        <title>Layer 1</title>
        <g id='svg_1'>
          <path
            d='m46.81,-942.36a3,3 0 0 0 -2.81,3l0,53.47l-30.25,2.53a3,3 0 0 0 -2.25,4.66l10,15a3,3 0 0 0 2.5,1.34l52,0a3,3 0 0 0 2.72,-1.72l10,-21a3,3 0 0 0 -2.97,-4.28l-35.75,2.97l0,-48.72c5.81,2.33 12,6.71 17.13,12.69c5.51,6.43 9.63,14.53 11.25,23.06l-21.38,0a3,3 0 1 0 0,6l25,0a3,3 0 0 0 2.97,-3.31c-1.17,-11.23 -6.4,-21.6 -13.31,-29.66c-6.92,-8.06 -15.47,-13.92 -23.94,-15.97a3,3 0 0 0 -0.91,-0.06zm-10.18,10a3,3 0 0 0 -2.22,1.47l-22,38a3,3 0 0 0 2.59,4.53l22,0a3,3 0 0 0 3,-3l0,-38a3,3 0 0 0 -3.37,-3zm-2.63,14.16l0,23.84l-13.81,0l13.81,-23.84zm47.03,35.25l-6.94,14.59l-48.46,0l-6.32,-9.47l61.72,-5.12z'
            display='inline'
            enableBackground='accumulate'
            fill={color}
            id='svg_2'
            strokeWidth='6'
            transform='translate(0 952.362)'
          />
        </g>
      </g>
    </svg>
  );
};

export const BookingLogo: React.FC<props> = ({ color, size }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 100 100' version='1.1'>
      <g className='layer'>
        <g id='svg_10'>
          <path
            d='m79.04,16.12c-0.63,-0.11 -1.34,-0.19 -2.05,-0.19l-3.73,0l0,-2.39c0,-1.53 -1.27,-2.8 -2.8,-2.8s-2.8,1.27 -2.8,2.8l0,2.39l-37.32,0l0,-2.39c0,-1.53 -1.27,-2.8 -2.8,-2.8s-2.8,1.27 -2.8,2.8l0,2.39l-3.73,0c-0.71,0 -1.38,0.07 -2.05,0.19c-4.7,0.97 -8.21,5.11 -8.21,10.08l0,50.79c0,5.67 4.59,10.26 10.26,10.26l55.98,0c5.67,0 10.26,-4.59 10.26,-10.26l0,-50.79c0,-4.96 -3.51,-9.11 -8.21,-10.08zm-58.03,5.41l3.73,0l0,3.36c0,1.53 1.27,2.8 2.8,2.8s2.8,-1.27 2.8,-2.8l0,-3.36l37.32,0l0,3.36c0,1.53 1.27,2.8 2.8,2.8s2.8,-1.27 2.8,-2.8l0,-3.36l3.73,0c2.57,0 4.66,2.09 4.66,4.66l0,4.22c0,2.57 -2.09,4.66 -4.66,4.66l-55.98,0c-2.57,0 -4.66,-2.09 -4.66,-4.66l0,-4.22c0,-2.57 2.09,-4.66 4.66,-4.66zm55.98,60.12l-55.98,0c-2.57,0 -4.66,-2.09 -4.66,-4.66l0,-37.47c1.42,0.71 2.99,1.16 4.66,1.16l55.98,0c1.68,0 3.25,-0.45 4.66,-1.16l0,37.47c0,2.57 -2.09,4.66 -4.66,4.66z'
            id='svg_1'
            fill={color}
          />
          <circle cx='30.97' cy='68.9' id='svg_2' r='3.73' fill={color} />
          <circle cx='30.97' cy='53.47' id='svg_3' r='3.73' fill={color} />
          <circle cx='49' cy='68.9' id='svg_4' r='3.73' fill={color} />
          <circle cx='49' cy='53.47' id='svg_5' r='3.73' fill={color} />
          <circle cx='67.03' cy='68.9' id='svg_6' r='3.73' fill={color} />
          <circle cx='67.03' cy='53.47' id='svg_7' r='3.73' fill={color} />
        </g>
      </g>
    </svg>
  );
};

export const GalleryLogo: React.FC<props> = ({ color, size }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 100 100' version='1.1'>
      <g className='layer'>
        <title>Layer 1</title>
        <path
          d='m76.1,80.52l-52.2,0a8.17,7.49 0 0 1 -8.14,-7.48l0,-36.2a8.16,7.48 0 0 1 8.14,-7.43l6.68,0a0.74,0.68 0 0 0 0.73,-0.53l0.9,-3.59a8.12,7.44 0 0 1 7.93,-5.8l19.69,0a8.12,7.44 0 0 1 7.93,5.8l0.9,3.59l0,0a0.74,0.68 0 0 0 0.73,0.53l6.69,0a8.16,7.48 0 0 1 8.13,7.43l0,36.2a8.17,7.49 0 0 1 -8.13,7.48zm-52.2,-44.31a0.73,0.67 0 0 0 -0.73,0.68l0,36.16a0.73,0.67 0 0 0 0.73,0.68l52.2,0a0.73,0.67 0 0 0 0.73,-0.68l0,-36.2a0.73,0.67 0 0 0 -0.73,-0.68l-6.69,0a8.12,7.44 0 0 1 -7.95,-5.8l-0.9,-3.59a0.74,0.68 0 0 0 -0.72,-0.53l-19.68,0a0.74,0.68 0 0 0 -0.72,0.53l-0.9,3.59a8.12,7.44 0 0 1 -7.95,5.8l-6.69,0.05zm26.1,7.89a11.94,10.94 0 1 0 11.94,10.94a11.94,10.94 0 0 0 -11.94,-10.94z'
          id='svg_1'
          fill={color}
        />
      </g>
    </svg>
  );
};

export const ContactLogo: React.FC<props> = ({ color, size }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 100 100' version='1.1'>
      <g className='layer'>
        <g id='svg_5'>
          <path
            d='m44.17,43.39a3.56,3.56 0 1 0 -5.31,4.73c1.99,2.22 4.85,3.76 8.04,4.4a3.56,3.56 0 0 0 7.04,0c3.19,-0.64 6.05,-2.17 8.04,-4.4a3.56,3.56 0 1 0 -5.31,-4.74c-0.6,0.68 -1.52,1.31 -2.68,1.74l0,-4.71l0.89,0a3.56,3.56 0 1 0 0,-7.12l-0.92,0a3.56,3.56 0 0 0 -7.07,0l-0.92,0a3.56,3.56 0 1 0 0,7.12l0.89,0l0,4.71c-1.17,-0.44 -2.08,-1.07 -2.69,-1.74l0,0.01z'
            fill={color}
            id='svg_1'
          />
          <path
            clipRule='evenodd'
            d='m9.36,62.77a8.9,8.9 0 0 0 8.86,6.38l-0.8,3.76c-0.47,2.22 0.8,4.44 2.96,5.12c22.85,7.14 37.18,7.07 60.02,-0.01c2.17,-0.67 3.45,-2.9 2.98,-5.12l-0.79,-3.73a8.9,8.9 0 0 0 8.18,-5.94l3.11,-8.81c3.09,-8.77 -1.09,-18.44 -9.6,-22.19l-26.63,-11.75a17.8,17.8 0 0 0 -14.49,0.05l-26.67,11.99c-8.11,3.64 -12.28,12.71 -9.78,21.24l2.64,9.01zm77.8,-10.71l-3.1,8.82a1.78,1.78 0 0 1 -1.85,1.18l-27.88,-2.62a42.71,42.71 0 0 0 -7.86,0l-28.41,2.59a1.78,1.78 0 0 1 -1.87,-1.27l-2.64,-9a10.68,10.68 0 0 1 5.86,-12.75l26.67,-11.99a10.68,10.68 0 0 1 8.69,-0.03l26.63,11.75a10.68,10.68 0 0 1 5.76,13.31zm-33.49,14.48l21.51,2.01l0.72,3.41c-9.89,2.92 -17.79,4.28 -25.51,4.29c-7.72,0 -15.62,-1.34 -25.5,-4.28l0.74,-3.47l21.49,-1.97a35.6,35.6 0 0 1 6.55,0.01z'
            fill={color}
            fillRule='evenodd'
            id='svg_2'
          />
        </g>
      </g>
    </svg>
  );
};
