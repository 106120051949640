import React from 'react';

interface props {
  color: string;
  size: string;
}

export const AgeLogo: React.FC<props> = ({ color, size }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 100 100' version='1.1'>
      <g id='svg_1' fill={color}>
        <path
          d='m50.5,30.44c5.74,0 10.38,-4.58 10.38,-10.26c0,-10.26 -10.38,-17.1 -10.38,-17.1s-10.38,6.84 -10.38,17.1c0,5.68 4.63,10.26 10.38,10.26z'
          id='svg_2'
        />
        <path
          d='m57.42,50.96l0,-13.68l-13.83,0l0,13.68l-27.67,0c-3.8,0 -6.92,3.08 -6.92,6.84l0,13.68c5.74,0 10.38,-4.58 10.38,-10.26c0,-1.92 1.52,-3.42 3.46,-3.42s3.46,1.5 3.46,3.42c0,5.68 4.63,10.26 10.38,10.26s10.38,-4.58 10.38,-10.26c0,-1.92 1.52,-3.42 3.46,-3.42s3.46,1.5 3.46,3.42c0,5.68 4.63,10.26 10.38,10.26s10.38,-4.58 10.38,-10.26c0,-1.92 1.52,-3.42 3.46,-3.42s3.46,1.5 3.46,3.42c0,5.68 4.63,10.26 10.38,10.26l0,-13.68c0,-3.76 -3.11,-6.84 -6.92,-6.84l-27.67,0z'
          id='svg_3'
        />
        <path
          d='m78.17,71.48c-3.18,4.17 -8.16,6.84 -13.83,6.84s-10.65,-2.67 -13.83,-6.84c-3.18,4.17 -8.16,6.84 -13.83,6.84s-10.65,-2.67 -13.83,-6.84c-3.18,4.17 -8.16,6.84 -13.83,6.84l0,20.52l83,0l0,-20.52c-5.67,0 -10.65,-2.67 -13.83,-6.84z'
          id='svg_4'
        />
      </g>
    </svg>
  );
};

export const DaysLogo: React.FC<props> = ({ color, size }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 100 100' version='1.1'>
      <g id='svg_1' transform='translate(0,-952.36218)' fill={color}>
        <path
          d='m29.03,960.36c-1.1,0 -2,0.9 -2,2l0,17c0,1.11 0.9,2 2,2c1.11,0 2,-0.89 2,-2l0,-17c0,-1.1 -0.89,-2 -2,-2zm42,0c-1.1,0 -2,0.9 -2,2l0,17c0,1.11 0.9,2 2,2c1.11,0 2,-0.89 2,-2l0,-17c0,-1.1 -0.89,-2 -2,-2zm-56,6c-4.44,0 -8.03,3.59 -8.03,8.03l0,17.97l86,0l0,-17.97c0,-4.44 -3.59,-8.03 -8.03,-8.03l-5.97,0l-3.97,0l0,8.53c1.23,1.1 2,2.7 2,4.47c0,3.32 -2.69,6 -6,6c-3.31,0 -6,-2.68 -6,-6c0,-1.77 0.77,-3.37 2,-4.47l0,-8.53l-34.03,0l0,8.5c1.25,1.1 2.03,2.71 2.03,4.5c0,3.32 -2.69,6 -6,6c-3.31,0 -6,-2.68 -6,-6c0,-1.76 0.76,-3.34 1.97,-4.44l0,-8.56l-9.97,0zm-8.03,30l0,39.97c0,4.45 3.59,8.03 8.03,8.03l69.94,0c4.44,0 8.03,-3.58 8.03,-8.03l0,-39.97l-86,0z'
          display='inline'
          enableBackground='accumulate'
          id='svg_2'
        />
      </g>
    </svg>
  );
};

export const PaxLogo: React.FC<props> = ({ color, size }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 100 100' version='1.1' fill={color}>
      <g id='svg_7'>
        <path d='m19.57,46.24c-5.16,-10.27 -3.1,-28.62 14.48,-28.62c17.58,0 19.63,18.35 14.48,28.62c-5.18,10.27 -23.77,10.27 -28.96,0z' id='svg_1' />
        <path d='m5,78.15c0,-8.43 10.12,-16.95 29.05,-16.95c18.93,0 29.05,8.52 29.05,16.95c-14.72,4.21 -43.39,4.21 -58.11,0z' id='svg_2' />
        <path d='m72.79,24.88c-15.06,0 -16.83,15.91 -12.4,24.79c4.43,8.91 20.36,8.91 24.79,0c4.43,-8.89 2.66,-24.79 -12.4,-24.79z' id='svg_3' />
        <path
          d='m72.79,63.62c-3.17,0 -5.98,0.22 -8.5,0.61c3.73,3.78 6.08,8.57 6.08,13.92l0,0.12c9.9,0.17 20.12,-0.68 26.63,-2.54c0,-6.05 -7.26,-12.11 -24.21,-12.11z'
          id='svg_4'
        />
      </g>
    </svg>
  );
};

export const ProgramLogo: React.FC<props> = ({ color, size }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 100 100' version='1.1' fill={color}>
      <g id='svg_1'>
        <path
          d='m78.91,44.52c-8.39,0 -15.57,5.22 -18.5,12.57c-2.34,-3.4 -6.26,-5.64 -10.7,-5.64c-4.43,0 -8.36,2.24 -10.7,5.64c-2.93,-7.36 -10.11,-12.57 -18.5,-12.57c-10.98,0 -19.91,8.93 -19.91,19.91c0,10.98 8.93,19.91 19.91,19.91c10.98,0 19.91,-8.93 19.91,-19.91c0,-5.12 4.17,-9.29 9.29,-9.29s9.29,4.17 9.29,9.29c0,10.98 8.93,19.91 19.91,19.91c10.98,0 19.91,-8.93 19.91,-19.91c0,-10.98 -8.93,-19.91 -19.91,-19.91zm-55.94,11.89l1.3,-1.3c0.72,-0.72 1.89,-0.72 2.6,0c0.72,0.72 0.72,1.89 0,2.61l-1.3,1.3c-0.36,0.36 -0.83,0.54 -1.3,0.54c-0.47,0 -0.94,-0.18 -1.3,-0.54c-0.72,-0.72 -0.72,-1.88 0,-2.6zm-9.13,14.34c-0.36,0.36 -0.83,0.54 -1.3,0.54c-0.47,0 -0.94,-0.18 -1.3,-0.54c-0.72,-0.72 -0.72,-1.89 0,-2.61l7.52,-7.52c0.72,-0.72 1.88,-0.72 2.6,0c0.72,0.72 0.72,1.88 0,2.6l-7.52,7.52zm16.02,-2.46l-5.44,5.44c-0.36,0.36 -0.83,0.54 -1.3,0.54c-0.47,0 -0.94,-0.18 -1.3,-0.54c-0.72,-0.72 -0.72,-1.88 0,-2.6l5.44,-5.44c0.72,-0.72 1.88,-0.72 2.6,0c0.72,0.72 0.72,1.89 0,2.61zm51.51,-11.89l1.3,-1.3c0.72,-0.72 1.88,-0.72 2.6,0c0.72,0.72 0.72,1.89 0,2.61l-1.3,1.3c-0.36,0.36 -0.83,0.54 -1.3,0.54c-0.47,0 -0.94,-0.18 -1.3,-0.54c-0.72,-0.72 -0.72,-1.88 0,-2.6zm-9.13,14.34c-0.36,0.36 -0.83,0.54 -1.3,0.54s-0.94,-0.18 -1.3,-0.54c-0.72,-0.72 -0.72,-1.89 0,-2.61l7.52,-7.52c0.72,-0.72 1.89,-0.72 2.61,0c0.72,0.72 0.72,1.88 0,2.6l-7.52,7.52zm16.02,-2.46l-5.44,5.44c-0.36,0.36 -0.83,0.54 -1.3,0.54c-0.47,0 -0.94,-0.18 -1.3,-0.54c-0.72,-0.72 -0.72,-1.88 0,-2.6l5.44,-5.44c0.72,-0.72 1.88,-0.72 2.6,0c0.72,0.72 0.72,1.89 0,2.61z'
          id='svg_2'
        />
        <path
          d='m17.91,23.14l4.27,-4.27c0.72,-0.72 0.72,-1.88 0,-2.6c-0.72,-0.72 -1.89,-0.72 -2.6,0l-4.58,4.58c-0.2,0.2 -0.35,0.45 -0.44,0.72l-8.01,23.87c1.48,-1.09 3.09,-2.02 4.81,-2.74l6.56,-19.54z'
          id='svg_3'
        />
        <path
          d='m81.52,23.14l6.56,19.54c1.72,0.73 3.33,1.65 4.81,2.74l-8.01,-23.87c-0.09,-0.27 -0.24,-0.52 -0.44,-0.72l-4.58,-4.58c-0.72,-0.72 -1.89,-0.72 -2.6,0c-0.72,0.72 -0.72,1.89 0,2.6l4.27,4.27z'
          id='svg_4'
        />
      </g>
    </svg>
  );
};

export const WhenLogo: React.FC<props> = ({ color, size }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 100 100' version='1.1' fill={color}>
      <g>
        <path
          d='m50,11a39,39 0 1 0 39,39a39,39 0 0 0 -39,-39zm23,50.28a2.66,2.66 0 0 1 -3.42,1.59l-20.49,-7.52a2.66,2.66 0 0 1 -1.75,-2.5l0,-25.9a2.66,2.66 0 1 1 5.33,0l0,24.05l18.79,6.87a2.66,2.66 0 0 1 1.54,3.41z'
          id='svg_1'
        />
      </g>
    </svg>
  );
};

export const WhereLogo: React.FC<props> = ({ color, size }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 100 100' version='1.1'>
      <g>
        <path
          d='m50,2.5c-23.2,0 -39.4,17 -39.4,41.4c0,25.8 36.7,51.8 38.3,52.8l1.1,0.8l1.1,-0.8c1.6,-1.1 38.3,-27.1 38.3,-52.8c0,-24.4 -16.2,-41.4 -39.4,-41.4l0,0zm0,57.9c-10.2,0 -18.5,-8.3 -18.5,-18.5c0,-10.2 8.3,-18.5 18.5,-18.5s18.5,8.3 18.5,18.5s-8.3,18.5 -18.5,18.5z'
          id='svg_1'
          fill={color}
        />
      </g>
    </svg>
  );
};

export const CheckListLogo: React.FC<props> = ({ color, size }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 100 100' version='1.1'>
      <g id='svg_1' transform='translate(0,-952.36218)'>
        <path
          d='m47.22,960.36c-0.59,0 -1.17,0.01 -1.75,0.03c-3.12,0.12 -6.25,0.59 -9.32,1.41c-22.35,5.99 -35.67,29.08 -29.68,51.44c5.99,22.35 29.08,35.68 51.43,29.69c22.36,-5.99 35.68,-29.09 29.69,-51.44a4,4 0 1 0 -7.72,2.06c4.87,18.18 -5.85,36.79 -24.03,41.66c-18.18,4.87 -36.79,-5.85 -41.66,-24.03c-4.87,-18.18 5.86,-36.79 24.04,-41.66c9.93,-2.66 20.53,-0.68 28.84,5.37a4,4 0 1 0 4.72,-6.46c-7.21,-5.26 -15.82,-8.03 -24.56,-8.07zm43.56,2.97a4,4 0 0 0 -2.72,1.28c-10.54,11.04 -31.62,33.83 -43.31,46.16l-14.07,-12.41a4.01,4.01 0 1 0 -5.31,6l17,15a4,4 0 0 0 5.56,-0.25c11.19,-11.7 34.87,-37.45 45.91,-49a4,4 0 0 0 -3.06,-6.78z'
          display='inline'
          enableBackground='accumulate'
          fill={color}
          id='svg_2'
        />
      </g>
    </svg>
  );
};
