import React from 'react';

interface props {
  size: string;
}
export const LargaWhiteLogo: React.FC<props> = ({ size }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 480 480' version='1.1'>
      <g className='layer'>
        <title>Layer 1</title>
        <g fill='none' id='svg_39' strokeWidth='2'>
          <path d='m156.49,144.1c7.84,3.8 15.51,7.96 23.21,12.04c4.98,2.64 7.06,6 13.04,5.59' id='svg_40' stroke='#80dbd8' />
          <path d='m192.73,161.74q0.87,1.14 0.26,2.27q-2.13,4 -6.2,11.1c-4.11,7.17 -8.33,15.86 -12.97,23.58' id='svg_41' stroke='#c0f1f2' />
          <path d='m173.83,198.68l-18.02,33.28' id='svg_42' stroke='#ffa4af' />
          <path d='m155.81,231.96l-13.56,24.24a1.24,1.23 14.4 0 0 1.08,1.83l38.75,-0.3' id='svg_43' stroke='#ffac91' />
          <path d='m182.08,257.74q1.26,0.75 1.4,1.98q0.6,4.96 0.39,11.99q-0.02,0.44 -0.39,0.67q-0.97,0.64 -2.13,0.33' id='svg_44' stroke='#ffc895' />
          <path
            d='m181.36,272.71q-4.39,-0.82 -6.56,2.59q-4,6.28 -8.27,12.35q-1.31,1.86 -3.22,3.01q-0.33,0.21 -0.23,0.58q0.04,0.15 0.16,0.27'
            id='svg_45'
            stroke='#80e4db'
          />
          <path d='m163.23,291.52q0.4,1.23 -0.06,1.53q-11,7.34 -22.32,15.28' id='svg_46' stroke='#808f9b' />
          <path d='m140.84,308.33l-39.97,27.29' id='svg_47' stroke='#80b1b7' />
          <path
            d='m100.88,335.62q-8.46,5.71 -17.38,11.82c-1.86,1.27 -2.02,2.29 -2.04,4.4q-0.1,8.46 0.04,16.93a1.53,1.52 89.5 0 0 1.52,1.51l136.27,-0.06'
            id='svg_48'
            stroke='#80e4db'
          />
          <path d='m219.28,370.21l185.68,0.03a0.85,0.84 0 0 0 0.85,-0.84l0.1,-32.62' id='svg_49' stroke='#80dbd8' />
          <path
            d='m405.91,336.77l0.07,-27.11a1.33,1.32 90 0 0 -1.32,-1.33q-44.74,0 -88.55,0.05c-2.64,0 -5.88,0.48 -8.22,0.33q-1.1,-0.06 -1.12,-1.16'
            id='svg_50'
            stroke='#80cecd'
          />
          <path d='m306.77,307.56l26.17,-15.99' id='svg_51' stroke='#808f9b' />
          <path d='m332.94,291.58q13.35,-8.45 25.24,-19.26' id='svg_52' stroke='#80dcf4' />
          <path
            d='m358.18,272.32c5.19,-4.46 11.47,-10.16 15.73,-16.15q2.41,-3.39 4.23,-5.73c7.19,-9.27 12.92,-20.56 17.13,-30.95q2.15,-5.3 4.12,-12.99c1.52,-5.92 3.28,-11.93 4.2,-17.98q0.39,-2.51 0.74,-4.74'
            id='svg_53'
            stroke='#808f9b'
          />
          <path d='m404.34,183.79q1.71,-14.38 0.92,-28.39' id='svg_54' stroke='#80c0d9' />
          <path d='m405.27,155.4q-1.37,-17.87 -5.9,-34.13' id='svg_55' stroke='#80b5cc' />
          <path
            d='m399.37,121.27q-0.62,-3.97 -1.18,-5.67q-6.45,-19.39 -17.65,-36.45c-3.68,-5.6 -7.32,-11.35 -11.52,-16.34c-9.02,-10.73 -19.08,-20.84 -30.74,-28.72q-8.49,-5.73 -11.22,-7.43c-28.86,-17.89 -64.86,-25.62 -98.37,-22.35q-2.17,0.22 -5.39,0.87c-4.48,0.91 -9.49,1.5 -13.43,2.44c-15.75,3.78 -28.23,7.86 -42.01,15.35c-5.97,3.25 -11.87,7.45 -17.72,11.41c-12.1,8.19 -22.39,19.34 -31.83,29.89q-2.85,3.19 -5.51,7.24c-4.2,6.38 -9.01,13.39 -12.26,19.27q-7.08,12.86 -11.78,26.82c-1.38,4.12 1.12,5.51 4.55,6.12q8.93,1.58 17.7,3.86c4.76,1.24 10.56,3.29 15.9,4.78q7.61,2.12 27.61,11.2q0.76,0.34 1.97,0.56'
            id='svg_56'
            stroke='#80dcf4'
          />
          <path d='m399.37,121.27q-16.16,-1.3 -31.08,3.53q-13.66,4.43 -27.1,11.14' id='svg_57' stroke='#0092c0' />
          <path
            d='m341.18,135.94q0.19,-3.6 -0.61,-6.57q-1.17,-4.34 -1.26,-5q-0.36,-2.58 -0.59,-5.17c-0.16,-1.75 -1.08,-3.42 -1.48,-5.17q-0.53,-2.35 -1.31,-3.77c-1.55,-2.82 -2.33,-4.81 -3.62,-7.1q-0.9,-1.59 -1.2,-2.3q-3.54,-8.1 -5.55,-10.34c-2.36,-2.64 -4.39,-5.53 -6.84,-8.02q-1.69,-1.72 -3.32,-3.51q-0.19,-0.21 -0.47,-0.21q-0.22,0 -0.34,-0.14q-2.35,-2.76 -5.59,-4.37q-0.23,-0.11 -0.21,-0.31q0.02,-0.21 -0.17,-0.3q-1.4,-0.65 -2.61,-1.59c-3.39,-2.63 -7.3,-4.64 -11.05,-6.69q-0.18,-0.09 -0.38,-0.1q-0.23,0 -0.47,0.01q-0.43,0.01 -0.76,-0.27q-0.62,-0.53 -1.45,-0.7q-0.4,-0.08 -0.72,-0.34q-1.67,-1.35 -3.91,-0.95'
            id='svg_58'
            stroke='#009dcd'
          />
          <path d='m287.27,63.04l-5.8,-1.86' id='svg_59' stroke='#00b8cd' />
          <path
            d='m281.47,61.18c-2.24,-1.36 -4.86,-1.54 -7.12,-2.57q-3.97,-1.8 -8.35,-2.19a1.61,1.57 -24 0 1 -0.81,-0.3q-0.32,-0.22 -0.68,-0.25q-4.36,-0.43 -8.48,-1.67'
            id='svg_60'
            stroke='#00c1d0'
          />
          <path d='m256.04,54.21l-7,-0.56' id='svg_61' stroke='#7fdceb' />
          <path
            d='m249.04,53.65q-17.09,-0.97 -33.75,4.81c-0.93,0.32 -1.81,1.36 -3.19,1.24q-0.44,-0.04 -0.85,0.09c-1.33,0.42 -2.46,0.75 -3.66,1.43q-2.89,1.63 -5.85,3.12q-1.68,0.83 -5.72,3.15c-5.67,3.24 -9.94,7.01 -14.9,12.38c-2.97,3.22 -6.84,6.28 -9.19,9.95q-5.59,8.72 -10.34,17.93q-0.65,1.27 0.65,1.65'
            id='svg_62'
            stroke='#4dcfdb'
          />
          <path
            d='m162.24,109.39c-2.27,3.17 -4.07,6.47 -4.09,10.33q0,0.45 -0.29,0.78c-0.88,1.02 -0.41,2.24 -1.19,3.47q-0.22,0.36 -0.25,0.78q-0.23,4.59 -1.42,9.04c-0.23,0.86 -0.23,1.77 -0.32,2.65c-0.17,1.68 -1.42,3.06 -0.22,4.81c0.75,1.1 1.21,1.95 2.04,2.86'
            id='svg_63'
            stroke='#00b8cd'
          />
          <path
            d='m405.27,155.4q-4.35,2.52 -5.94,2.9c-4.32,1.02 -10.42,0.48 -14.77,1.11q-14.51,2.09 -27.68,8.65c-4.3,2.14 -8.51,4.48 -13.06,5.75q-11.96,3.3 -25.09,4.11q-1.69,0.11 -1.1,1.69q1.49,3.99 3.51,8.46c0.86,1.91 2.32,3.67 3.47,5.5'
            id='svg_64'
            stroke='#0076a5'
          />
          <path d='m324.6,193.57q-0.74,-0.13 -1.13,0.04' id='svg_65' stroke='#004567' />
          <path d='m323.47,193.62l-20.59,-36.3' id='svg_66' stroke='#807e61' />
          <path d='m256.04,54.21q-0.82,8.62 -0.29,17.2' id='svg_67' stroke='#7fe4d3' />
          <path d='m255.75,71.41c-0.74,4.25 0.12,8.76 -0.03,12.29c-0.92,22.79 -0.72,44.97 -0.59,74.07' id='svg_68' stroke='#7fdbd0' />
          <path d='m255.13,157.77l0.42,35.34' id='svg_69' stroke='#7fbfd0' />
          <path
            d='m255.55,193.11l-0.04,69.49q0,0.82 0.82,0.82q16.59,0.04 32.08,-0.08q1.3,-0.01 3.28,-0.19c8.25,-0.76 16.76,-0.43 24.99,-0.44a0.67,0.66 84.9 0 1 0.65,0.56q0.77,4.42 0.05,9.52'
            id='svg_70'
            stroke='#7f8f92'
          />
          <path
            d='m317.4,272.8q-0.82,0.43 -2,0.47c-11.31,0.39 -24.43,-0.52 -33.77,-0.55q-32.01,-0.09 -64,0.13c-3.31,0.02 -6.77,2.31 -10.2,4.8c-6.5,4.71 -13.63,8.49 -20.37,12.85c-1.43,0.92 -2.94,0.3 -4.12,1.17'
            id='svg_71'
            stroke='#7fdceb'
          />
          <path d='m182.94,291.67l-16.37,-0.03' id='svg_72' stroke='#7f8f92' />
          <path
            d='m166.57,291.64q-0.63,-0.19 -0.96,-0.46a0.51,0.5 -54.4 0 1 -0.11,-0.68q3.81,-5.52 10.05,-15.54q1.34,-2.16 4.2,-1.97'
            id='svg_73'
            stroke='#7fe4d3'
          />
          <path d='m179.75,272.99l7.56,0.18' id='svg_74' stroke='#fec88c' />
          <path
            d='m187.31,273.17l25.6,-0.16a1.37,1.36 -70.4 0 0 1.03,-0.49l7.06,-8.63q0.34,-0.43 0.9,-0.43l26.75,-0.1q0.54,0 0.56,-0.55l0.22,-4.72'
            id='svg_75'
            stroke='#7f8f92'
          />
          <path d='m249.44,258.09l-0.05,-24.78a1.22,1.22 0 0 0 -1.22,-1.22l-65.27,0.06' id='svg_76' stroke='#fec88c' />
          <path d='m182.9,232.16l-25.45,0.04' id='svg_77' stroke='#feac88' />
          <path
            d='m157.45,232.19q-0.77,0.69 -1.13,-0.03q-0.22,-0.43 0.07,-0.81c1.68,-2.22 3.81,-1.07 5.77,-1.07q22.39,-0.08 44.81,0.04'
            id='svg_78'
            stroke='#fea3a6'
          />
          <path d='m206.97,230.33l41.55,-0.15a0.53,0.53 0 0 0 0.53,-0.53l0.04,-23.23' id='svg_79' stroke='#fe8596' />
          <path d='m249.09,206.42l0.24,-3.48' id='svg_80' stroke='#fea3a6' />
          <path
            d='m249.33,202.94q0.26,-2.26 0.11,-4.9q-0.22,-3.46 -0.19,-4.87q0.29,-19.65 0.22,-39.3c-0.01,-2.68 -0.45,-7.69 -0.03,-11.6q0.08,-0.76 0.02,-0.85q-0.76,-1.01 -1.32,0.13c-4.23,8.8 -9.45,17 -14.54,25.26q-4.16,6.76 -8.17,11.16c-8.01,8.81 -17.03,16.61 -27.97,21.58q-0.84,0.39 -2.21,1.89a1.28,1.26 15 0 1 -0.72,0.4l-4.24,0.79'
            id='svg_81'
            stroke='#fef5dd'
          />
          <path d='m190.31,202.64l-16.94,0.38a0.65,0.65 0 0 1 -0.57,-0.97l1.6,-2.83' id='svg_82' stroke='#fea3a6' />
          <path
            d='m174.39,199.22c4.88,-8.45 9.52,-17.49 14.21,-25.54q3.65,-6.27 7.23,-12.58c0.75,-1.33 2.6,-1.78 3.63,-2.79c5.49,-5.37 9.77,-11.34 16.06,-15.73c11,-7.69 27.35,-16.04 32.02,-29.97q1.24,-3.69 1.38,-11.19q0.27,-14.33 0.04,-28.66'
            id='svg_83'
            stroke='#bff0ea'
          />
          <path d='m249.04,53.65l-0.08,19.11' id='svg_84' stroke='#ccf2de' />
          <path
            d='m248.96,72.76l-0.03,-4.38q-0.04,-5.08 -3.22,-1.1c-1.78,2.23 -1.06,6.71 -3.37,9.23c-1.09,1.2 -3.01,1.83 -4.45,2.91'
            id='svg_85'
            stroke='#8ee4d9'
          />
          <path d='m237.89,79.42l-50.67,11.93q-0.43,0.11 -0.67,0.48q-2.08,3.23 -4.08,6.49q-1.33,2.16 -5.42,4.07' id='svg_86' stroke='#4dd7c2' />
          <path d='m177.05,102.39q-7.51,2.82 -14.81,6.99' id='svg_87' stroke='#4dcebf' />
          <path d='m341.18,135.94l-6.89,3.74' id='svg_88' stroke='#0076a5' />
          <path
            d='m287.27,63.04q10.22,5.06 15.12,9.68q9.76,9.21 12.06,11.99c9.71,11.76 14.22,22.37 17.78,37.39q1.26,5.33 2.05,17.59'
            id='svg_89'
            stroke='#009cb2'
          />
          <path d='m334.29,139.68q-14.62,10.47 -31.41,17.63' id='svg_90' stroke='#0091a5' />
          <path d='m302.87,157.32l-6.97,-12.17' id='svg_91' stroke='#80a46e' />
          <path
            d='m295.9,145.14c-2.21,-3.48 -4.02,-7.54 -5.95,-10.78q-4.32,-7.23 -8.33,-14.64q-4.4,-8.12 -8.52,-14.51q-5.73,-8.88 -13.52,-21.25q-0.37,-0.57 -1.04,-0.51l-0.27,0.02q-0.43,0.04 -0.26,0.44q2.24,5.09 3.88,9.12c0.39,0.94 0.13,1.82 0.47,2.64q2.91,7.2 6,18.34q5.87,21.23 8.36,39.86'
            id='svg_92'
            stroke='#806178'
          />
          <path d='m276.72,153.86l0.04,3.34' id='svg_93' stroke='#808088' />
          <path d='m276.76,157.21q-4.21,0.32 -8.65,-0.29q-4.81,-0.65 -11.23,-0.9q-0.41,-0.02 -0.66,0.31l-1.08,1.43' id='svg_94' stroke='#009cb2' />
          <path
            d='m281.47,61.18q-0.04,1 0.47,1.33a1.17,1.17 0 0 1 -0.01,1.95q-1.95,1.25 -3.95,1.3q-1.82,0.05 -6.05,2q-3.61,1.66 -4.72,1.68q-7.67,0.11 -9.72,0.39q-1.9,0.25 -1.73,1.59'
            id='svg_95'
            stroke='#00c0b4'
          />
          <path d='m174.39,199.22q-0.48,0.32 -0.56,-0.54' id='svg_96' stroke='#c095a2' />
          <path
            d='m192.73,161.74q1.38,0.71 1.88,0.07q4.98,-6.33 9.05,-14.43c1.24,-2.46 3.25,-4.92 4.22,-6.89q1.42,-2.89 2.22,-4.25c0.68,-1.17 0.86,-2.35 1.52,-3.51c1.62,-2.8 3.07,-5.94 4.34,-7.94q3.48,-5.49 6.54,-11.2c0.42,-0.8 1.17,-1.43 1.4,-2.74q0.07,-0.43 0.34,-0.78q3.16,-4.07 4.9,-9.05'
            id='svg_97'
            stroke='#41cdcb'
          />
          <path d='m229.15,101.01l9.79,-21.12q0.17,-0.38 -0.22,-0.48q-0.41,-0.12 -0.82,0.01' id='svg_98' stroke='#41d6ce' />
          <path
            d='m229.15,101.01c-0.91,-1.86 -4.02,-1.31 -5.74,-1.78q-2.47,-0.65 -3.08,-0.69c-12.19,-0.74 -24.18,-0.99 -35.57,1.91q-3.76,0.96 -7.72,1.94'
            id='svg_99'
            stroke='#00c0b4'
          />
          <path d='m358.18,272.32q-6.97,0.74 -17.68,0.73q-11.52,-0.02 -23.1,-0.25' id='svg_100' stroke='#006c8f' />
          <path
            d='m404.34,183.79q-15.95,-4.18 -32.9,-0.06q-10.09,2.45 -16.96,4.29c-10.41,2.79 -18.85,4.51 -28.48,6.26q-0.47,0.09 -0.79,-0.1q-0.4,-0.23 -0.61,-0.6'
            id='svg_101'
            stroke='#005074'
          />
          <path
            d='m323.47,193.62c5.97,11.74 12.47,23.41 19.45,34.61c6.38,10.24 10.07,19.56 16.54,28.5a0.71,0.71 0 0 1 -0.56,1.13l-12.55,0.13a5.94,5.85 57.5 0 1 -2.64,-0.61c-4.71,-2.34 -9.25,-4.4 -14.53,-6q-3.44,-1.04 -6.85,-2.15q-31.79,-10.42 -64.05,-3.29q-3.64,0.8 -0.88,-1.7q4.68,-4.23 8.45,-11.01'
            id='svg_102'
            stroke='#805830'
          />
          <path d='m277.82,191.11l-22.27,2' id='svg_103' stroke='#005074' />
          <path d='m187.45,258.1l-0.14,15.07' id='svg_104' stroke='#805830' />
          <path d='m179.75,272.99q1.05,0.08 1.6,-0.29' id='svg_105' stroke='#80ad71' />
          <path d='m249.33,202.94l-59.03,-0.3' id='svg_106' stroke='#ff9a95' />
          <path d='m166.57,291.64q-1.47,0.39 -3.34,-0.13' id='svg_107' stroke='#007477' />
          <path d='m249.44,258.09l-42.9,-0.15' id='svg_108' stroke='#805830' />
          <path d='m332.94,291.58l-150.01,0.09' id='svg_109' stroke='#006c8f' />
          <path d='m182.9,232.16c4.31,2.81 6.61,5.5 10.09,9.77c1.39,1.7 2.31,4.28 3.51,5.68q4.84,5.7 10.04,10.33' id='svg_110' stroke='#ff7526' />
          <path d='m206.54,257.94l-19.09,0.16' id='svg_111' stroke='#803c2c' />
          <path d='m187.45,258.1l-2.9,-4.72a0.4,0.4 0 0 0 -0.74,0.22q0.14,3.51 -1.72,4.12' id='svg_112' stroke='#ff7526' />
          <path d='m306.77,307.56l-2.12,0.77' id='svg_113' stroke='#005e69' />
          <path d='m304.66,308.34l-163.81,-0.01' id='svg_114' stroke='#004153' />
          <path
            d='m249.09,206.42l-0.14,-2.74q-0.02,-0.33 -0.35,-0.33l-0.73,0q-0.33,0 -0.29,0.33c0.16,1.12 -0.22,2.02 -1.19,2.73c-9.06,6.68 -17.98,15.63 -28.96,18.72q-3.53,1 -4.81,1.58a1.69,1.65 -55.9 0 1 -0.79,0.16q-1.51,-0.04 -2.89,0.59q-0.35,0.16 -0.55,0.48q-0.45,0.74 -1.24,0.75q-0.43,0.01 -0.83,0.17l-0.75,0.3q-0.61,0.24 -0.01,0.52l1.4,0.65'
            id='svg_115'
            stroke='#ff2a4e'
          />
          <path d='m157.45,232.19q-1.12,1.2 -1.64,-0.23' id='svg_116' stroke='#ff5140' />
          <path d='m277.03,159.48l-0.27,-2.27' id='svg_117' stroke='#806488' />
          <path
            d='m295.9,145.14c1.58,5.82 4.37,11.81 5.53,16.97c2.36,10.48 3.45,24.54 0.01,34.8q-5.5,16.42 -18.84,28.44c-2.98,2.68 -6.42,4.83 -9.49,7.28q-2.66,2.11 -5.44,3.73a1.15,1.14 -10.2 0 1 -1.7,-1.18q0.13,-0.75 -0.13,-1.96'
            id='svg_118'
            stroke='#ff4e34'
          />
          <path d='m265.84,233.22c1.91,-3.41 3.92,-6.76 5.29,-10.33q5.83,-15.16 6.7,-31.77' id='svg_119' stroke='#80153a' />
          <path d='m277.82,191.11q1.03,-15.73 -0.8,-31.64' id='svg_120' stroke='#804678' />
          <path d='m277.03,159.48q0.76,-3.1 -0.3,-5.61' id='svg_121' stroke='#ff2a4e' />
          <path d='m265.65,335.69l-4.2,0.01' id='svg_122' stroke='#008d90' />
          <path d='m261.45,335.7l-160.58,-0.08' id='svg_123' stroke='#009693' />
          <path
            d='m405.91,336.77q-2.15,-1.6 -5.12,-1.57c-8.27,0.06 -16.7,0.43 -24.81,0.32q-13.93,-0.17 -27.86,-0.05q-41.2,0.36 -82.47,0.22'
            id='svg_124'
            stroke='#00aaa6'
          />
          <path
            d='m265.65,335.69q0.83,-0.71 1.27,-1.48c1.86,-3.23 5.4,-4.98 8.78,-6.99c2.03,-1.2 5.3,-3.55 8.46,-5.25q9.24,-4.98 20.49,-13.63'
            id='svg_125'
            stroke='#007f85'
          />
          <path
            d='m219.28,370.21q-1.04,-0.59 -1.84,-0.62a0.71,0.71 0 0 1 -0.53,-1.15q1.55,-1.95 3.35,-3.3q9.25,-6.96 18.81,-13.52c4.53,-3.11 10.63,-6.1 15.69,-9.73q0.06,-0.04 3.29,-2.05q2.67,-1.66 3.4,-4.14'
            id='svg_126'
            stroke='#00c0b4'
          />
          <path
            d='m335.94,449.05q0.47,2.05 0.12,4.12a1.04,1.04 0 0 1 -0.63,0.8c-8.99,3.83 -19.56,3.18 -26.62,-3.77c-9.82,-9.68 -7.38,-29.36 7.28,-33.36c9.99,-2.73 16.78,1.34 22.95,8.8a0.56,0.54 -40.6 0 0 0.79,0.05c4.72,-4.27 10.26,-7.59 14.63,-12.21q0.32,-0.34 0.05,-0.73q-10.61,-15.37 -29.5,-17.05c-39.46,-3.51 -59.85,47.96 -27.25,71.79c7.42,5.42 14.73,7.97 24.21,8.1c3.39,0.04 6.93,-0.65 10.38,-1.19c7.86,-1.22 16.38,-0.64 24.33,-0.64a0.5,0.5 0 0 0 0.5,-0.5l0.05,-41.37a0.56,0.56 0 0 0 -0.56,-0.56l-32.19,0.06q-0.56,0 -0.56,0.55l-0.01,15.68a0.76,0.76 0 0 0 0.75,0.76l10.61,0.13q0.53,0 0.65,0.52'
            id='svg_127'
            stroke='#808f9b'
          />
          <path
            d='m67.75,454.61q-0.43,-1.8 -0.43,-3.71q0.07,-27.07 0.01,-53.87a0.56,0.56 0 0 0 -0.56,-0.56l-21.43,0.05q-0.36,0 -0.36,0.36l-0.07,75.82a1.03,1.03 0 0 0 1.03,1.04l57,0.02q0.48,0 0.48,-0.48l0.03,-17.87a0.49,0.49 0 0 0 -0.49,-0.49l-34.78,0.03a0.44,0.44 0 0 1 -0.43,-0.34'
            id='svg_128'
            stroke='#808f9b'
          />
          <path
            d='m136.35,459.38l25.29,-0.07a0.91,0.91 -10.5 0 1 0.85,0.58l5.07,13.27a0.98,0.96 79.1 0 0 0.9,0.62l23.19,-0.04a0.3,0.3 0 0 0 0.28,-0.42l-32.09,-76.44a0.56,0.55 78.5 0 0 -0.51,-0.35l-20.3,0.11a0.74,0.73 -78.6 0 0 -0.67,0.45c-1.28,3.16 -2.14,6.83 -3.31,9.63q-10.46,24.95 -20.89,49.91q-0.91,2.17 -1.69,4.39c-1.45,4.16 -3.52,8.03 -5.03,12.17a0.42,0.41 9.7 0 0 0.39,0.56l22.08,-0.04a1.68,1.67 9.7 0 0 1.58,-1.11l4.62,-13.06a0.25,0.23 8.7 0 1 0.23,-0.16'
            id='svg_129'
            stroke='#808f9b'
          />
          <path
            d='m223.69,452.52l11.07,-0.22a2.17,2.16 72.3 0 1 1.85,0.96l12.89,19.28a3,3 0 0 0 2.52,1.34l23.14,-0.04q1.08,0 0.48,-0.91c-3.07,-4.55 -7.58,-9.66 -9.99,-12.74c-3.31,-4.21 -7.48,-8.49 -10.23,-13.04a1.38,1.38 0 0 1 0.55,-1.95c15.52,-8.07 15.03,-30.63 3.03,-41.44c-6.11,-5.5 -12.71,-7.21 -20.92,-7.28q-17.35,-0.14 -34.98,0.06a0.32,0.32 0 0 0 -0.32,0.32l0.04,76.51a0.37,0.37 0 0 0 0.37,0.37l19.42,0.01q0.45,0 0.45,-0.44l0,-20.14a0.66,0.65 89.6 0 1 0.65,-0.66'
            id='svg_130'
            stroke='#808f9b'
          />
          <path
            d='m420.09,460.27c1.28,4.15 2.99,8.17 3.93,12.52a1.43,1.43 0 0 0 1.41,1.13l22.91,-0.15a0.19,0.18 81.4 0 0 0.17,-0.24q-1.06,-3.63 -2.48,-7.15c-5.33,-13.18 -11.35,-26.04 -16.63,-39.23q-0.8,-2 -3.5,-7.99c-3.24,-7.2 -5.47,-14.95 -8.59,-22.06a1.02,1.02 0 0 0 -0.93,-0.61l-20.17,-0.01q-0.57,-0.01 -0.8,0.53l-31.41,75.93a0.7,0.69 -78.6 0 0 0.64,0.97l22.27,-0.04a1.38,1.36 -79.3 0 0 1.27,-0.88l4.72,-12.42a1.66,1.66 0 0 1 1.56,-1.07l24.79,0.13a0.91,0.91 0 0 1 0.85,0.64'
            id='svg_131'
            stroke='#808f9b'
          />
          <path
            d='m141.14,444.05a0.82,0.82 0 0 0 0.77,1.08l14.82,-0.03a0.82,0.82 0 0 0 0.77,-1.08l-7.45,-21.92a0.82,0.82 0 0 0 -1.54,0.01l-7.37,21.94'
            id='svg_132'
            stroke='#808f9b'
          />
          <path
            d='m223.02,416.85l0.05,16.26a1.08,1.08 0 0 0 1.08,1.07l14.35,-0.04a8.65,8.42 -0.2 0 0 8.62,-8.45l0,-1.58a8.65,8.42 -0.2 0 0 -8.69,-8.38l-14.35,0.04a1.08,1.08 0 0 0 -1.07,1.08'
            id='svg_133'
            stroke='#808f9b'
          />
          <path
            d='m398.15,444.05a0.63,0.63 0 0 0 0.6,0.82l14.63,-0.02a0.63,0.63 0 0 0 0.59,-0.83l-7.35,-22.03a0.63,0.63 0 0 0 -1.19,0l-7.28,22.05'
            id='svg_134'
            stroke='#808f9b'
          />
        </g>
        <path
          d='m399.37,121.27q-16.16,-1.3 -31.08,3.53q-13.66,4.43 -27.1,11.14q0.19,-3.6 -0.61,-6.57q-1.17,-4.34 -1.26,-5q-0.36,-2.58 -0.59,-5.17c-0.16,-1.75 -1.08,-3.42 -1.48,-5.17q-0.53,-2.35 -1.31,-3.77c-1.55,-2.82 -2.33,-4.81 -3.62,-7.1q-0.9,-1.59 -1.2,-2.3q-3.54,-8.1 -5.55,-10.34c-2.36,-2.64 -4.39,-5.53 -6.84,-8.02q-1.69,-1.72 -3.32,-3.51q-0.19,-0.21 -0.47,-0.21q-0.22,0 -0.34,-0.14q-2.35,-2.76 -5.59,-4.37q-0.23,-0.11 -0.21,-0.31q0.02,-0.21 -0.17,-0.3q-1.4,-0.65 -2.61,-1.59c-3.39,-2.63 -7.3,-4.64 -11.05,-6.69q-0.18,-0.09 -0.38,-0.1q-0.23,0 -0.47,0.01q-0.43,0.01 -0.76,-0.27q-0.62,-0.53 -1.45,-0.7q-0.4,-0.08 -0.72,-0.34q-1.67,-1.35 -3.91,-0.95l-5.8,-1.86c-2.24,-1.36 -4.86,-1.54 -7.12,-2.57q-3.97,-1.8 -8.35,-2.19a1.61,1.57 -24 0 1 -0.81,-0.3q-0.32,-0.22 -0.68,-0.25q-4.36,-0.43 -8.48,-1.67l-7,-0.56q-17.09,-0.97 -33.75,4.81c-0.93,0.32 -1.81,1.36 -3.19,1.24q-0.44,-0.04 -0.85,0.09c-1.33,0.42 -2.46,0.75 -3.66,1.43q-2.89,1.63 -5.85,3.12q-1.68,0.83 -5.72,3.15c-5.67,3.24 -9.94,7.01 -14.9,12.38c-2.97,3.22 -6.84,6.28 -9.19,9.95q-5.59,8.72 -10.34,17.93q-0.65,1.27 0.65,1.65c-2.27,3.17 -4.07,6.47 -4.09,10.33q0,0.45 -0.29,0.78c-0.88,1.02 -0.41,2.24 -1.19,3.47q-0.22,0.36 -0.25,0.78q-0.23,4.59 -1.42,9.04c-0.23,0.86 -0.23,1.77 -0.32,2.65c-0.17,1.68 -1.42,3.06 -0.22,4.81c0.75,1.1 1.21,1.95 2.04,2.86q-1.21,-0.22 -1.97,-0.56q-20,-9.08 -27.61,-11.2c-5.33,-1.49 -11.13,-3.54 -15.9,-4.78q-8.77,-2.28 -17.7,-3.86c-3.43,-0.61 -5.94,-2 -4.55,-6.12q4.7,-13.97 11.78,-26.82c3.25,-5.88 8.06,-12.88 12.26,-19.27q2.66,-4.05 5.51,-7.24c9.44,-10.55 19.72,-21.7 31.83,-29.89c5.85,-3.96 11.74,-8.17 17.72,-11.41c13.78,-7.49 26.26,-11.57 42.01,-15.35c3.94,-0.94 8.95,-1.53 13.43,-2.44q3.22,-0.65 5.39,-0.87c33.5,-3.27 69.51,4.46 98.37,22.35q2.73,1.7 11.22,7.43c11.66,7.88 21.72,17.99 30.74,28.72c4.2,4.99 7.84,10.74 11.52,16.34q11.21,17.06 17.65,36.45q0.56,1.69 1.18,5.67l-0.01,0.02z'
          fill='#00b9e8'
          id='svg_38'
        />
        <path
          d='m249.04,53.65l-0.08,19.11l-0.03,-4.38q-0.04,-5.08 -3.22,-1.1c-1.78,2.23 -1.06,6.71 -3.37,9.23c-1.09,1.2 -3.01,1.83 -4.45,2.91l-50.67,11.93q-0.43,0.11 -0.67,0.48q-2.08,3.23 -4.08,6.49q-1.33,2.16 -5.42,4.07q-7.51,2.82 -14.81,6.99q-1.3,-0.38 -0.65,-1.65q4.75,-9.21 10.34,-17.93c2.35,-3.67 6.22,-6.73 9.19,-9.95c4.96,-5.37 9.23,-9.14 14.9,-12.38q4.04,-2.31 5.72,-3.15q2.96,-1.49 5.85,-3.12c1.2,-0.67 2.33,-1 3.66,-1.43q0.41,-0.13 0.85,-0.09c1.38,0.13 2.26,-0.91 3.19,-1.24q16.67,-5.77 33.75,-4.81l0,0.02z'
          fill='#9ae5cd'
          id='svg_37'
        />
        <path
          d='m249.04,53.65l7,0.56q-0.82,8.62 -0.29,17.2c-0.74,4.25 0.12,8.76 -0.03,12.29c-0.92,22.79 -0.72,44.97 -0.59,74.07l0.42,35.34l-0.04,69.49q0,0.82 0.82,0.82q16.59,0.04 32.08,-0.08q1.3,-0.01 3.28,-0.19c8.25,-0.76 16.76,-0.43 24.99,-0.44a0.67,0.66 84.9 0 1 0.65,0.56q0.77,4.42 0.05,9.52q-0.82,0.43 -2,0.47c-11.31,0.39 -24.43,-0.52 -33.77,-0.55q-32.01,-0.09 -64,0.13c-3.31,0.02 -6.77,2.31 -10.2,4.8c-6.5,4.71 -13.63,8.49 -20.37,12.85c-1.43,0.92 -2.94,0.3 -4.12,1.17l-16.37,-0.03q-0.63,-0.19 -0.96,-0.46a0.51,0.5 -54.4 0 1 -0.11,-0.68q3.81,-5.52 10.05,-15.54q1.34,-2.16 4.2,-1.97l7.56,0.18l25.6,-0.16a1.37,1.36 -70.4 0 0 1.03,-0.49l7.06,-8.63q0.34,-0.43 0.9,-0.43l26.75,-0.1q0.54,0 0.56,-0.55l0.22,-4.72l-0.05,-24.78a1.22,1.22 0 0 0 -1.22,-1.22l-65.27,0.06l-25.45,0.04q-0.77,0.69 -1.13,-0.03q-0.22,-0.43 0.07,-0.81c1.68,-2.22 3.81,-1.07 5.77,-1.07q22.39,-0.08 44.81,0.04l41.55,-0.15a0.53,0.53 0 0 0 0.53,-0.53l0.04,-23.23l0.24,-3.48q0.26,-2.26 0.11,-4.9q-0.22,-3.46 -0.19,-4.87q0.29,-19.65 0.22,-39.3c-0.01,-2.68 -0.45,-7.69 -0.03,-11.6q0.08,-0.76 0.02,-0.85q-0.76,-1.01 -1.32,0.13c-4.23,8.8 -9.45,17 -14.54,25.26q-4.16,6.76 -8.17,11.16c-8.01,8.81 -17.03,16.61 -27.97,21.58q-0.84,0.39 -2.21,1.89a1.28,1.26 15 0 1 -0.72,0.4l-4.24,0.79l-16.94,0.38a0.65,0.65 0 0 1 -0.57,-0.97l1.6,-2.83c4.88,-8.45 9.52,-17.49 14.21,-25.54q3.65,-6.27 7.23,-12.58c0.75,-1.33 2.6,-1.78 3.63,-2.79c5.49,-5.37 9.77,-11.34 16.06,-15.73c11,-7.69 27.35,-16.04 32.02,-29.97q1.24,-3.69 1.38,-11.19q0.27,-14.33 0.04,-28.66l0.08,-19.11l0.04,0.03z'
          fill='#fdfeee'
          id='svg_36'
          transform='matrix(1 0 0 1 0 0)'
        />
        <path
          d='m256.04,54.21q4.12,1.24 8.48,1.67q0.36,0.04 0.68,0.25a1.61,1.57 -24 0 0 0.81,0.3q4.38,0.39 8.35,2.19c2.26,1.03 4.88,1.21 7.12,2.57q-0.04,1 0.47,1.33a1.17,1.17 0 0 1 -0.01,1.95q-1.95,1.25 -3.95,1.3q-1.82,0.05 -6.05,2q-3.61,1.66 -4.72,1.68q-7.67,0.11 -9.72,0.39q-1.9,0.25 -1.73,1.59q-0.54,-8.58 0.29,-17.2l-0.02,-0.02z'
          fill='#00c9b7'
          id='svg_35'
        />
        <path
          d='m281.47,61.18l5.8,1.86q10.22,5.06 15.12,9.68q9.76,9.21 12.06,11.99c9.71,11.76 14.22,22.37 17.78,37.39q1.26,5.33 2.05,17.59q-14.62,10.47 -31.41,17.63l-6.97,-12.17c-2.21,-3.48 -4.02,-7.54 -5.95,-10.78q-4.32,-7.23 -8.33,-14.64q-4.4,-8.12 -8.52,-14.51q-5.73,-8.88 -13.52,-21.25q-0.37,-0.57 -1.04,-0.51l-0.27,0.02q-0.43,0.04 -0.26,0.44q2.24,5.09 3.88,9.12c0.39,0.94 0.13,1.82 0.47,2.64q2.91,7.2 6,18.34q5.87,21.23 8.36,39.86l0.04,3.34q-4.21,0.32 -8.65,-0.29q-4.81,-0.65 -11.23,-0.9q-0.41,-0.02 -0.66,0.31l-1.08,1.43c-0.13,-29.1 -0.33,-51.28 0.59,-74.07c0.14,-3.53 -0.71,-8.04 0.03,-12.29q-0.17,-1.34 1.73,-1.59q2.05,-0.28 9.72,-0.39q1.11,-0.02 4.72,-1.68q4.23,-1.95 6.05,-2q2.01,-0.05 3.95,-1.3a1.17,1.17 0 0 0 0.01,-1.95q-0.51,-0.33 -0.47,-1.33l0,0.01z'
          fill='#00b7b1'
          id='svg_34'
        />
        <path
          d='m341.18,135.94l-6.89,3.74q-0.79,-12.26 -2.05,-17.59c-3.56,-15.02 -8.07,-25.62 -17.78,-37.39q-2.3,-2.78 -12.06,-11.99q-4.9,-4.63 -15.12,-9.68q2.24,-0.4 3.91,0.95q0.31,0.26 0.72,0.34q0.83,0.17 1.45,0.7q0.33,0.28 0.76,0.27q0.23,-0.01 0.47,-0.01q0.2,0.01 0.38,0.1c3.76,2.04 7.67,4.06 11.05,6.69q1.21,0.93 2.61,1.59q0.19,0.09 0.17,0.3q-0.03,0.21 0.21,0.31q3.25,1.6 5.59,4.37q0.13,0.14 0.34,0.14q0.28,0 0.47,0.21q1.63,1.78 3.32,3.51c2.45,2.49 4.48,5.39 6.84,8.02q2.01,2.24 5.55,10.34q0.3,0.72 1.2,2.3c1.29,2.29 2.07,4.28 3.62,7.1q0.78,1.42 1.31,3.77c0.4,1.75 1.32,3.42 1.48,5.17q0.23,2.59 0.59,5.17q0.09,0.66 1.26,5q0.8,2.97 0.61,6.57l-0.01,0z'
          fill='#0080b2'
          id='svg_33'
        />
        <path
          d='m248.96,72.76q0.23,14.34 -0.04,28.66q-0.14,7.49 -1.38,11.19c-4.66,13.93 -21.01,22.28 -32.02,29.97c-6.28,4.39 -10.57,10.36 -16.06,15.73c-1.03,1 -2.88,1.46 -3.63,2.79q-3.59,6.31 -7.23,12.58c-4.69,8.05 -9.33,17.1 -14.21,25.54q-0.48,0.32 -0.56,-0.54c4.64,-7.72 8.87,-16.41 12.97,-23.58q4.06,-7.1 6.2,-11.1q0.61,-1.13 -0.26,-2.27q1.38,0.71 1.88,0.07q4.98,-6.33 9.05,-14.43c1.24,-2.46 3.25,-4.92 4.22,-6.89q1.42,-2.89 2.22,-4.25c0.68,-1.17 0.86,-2.35 1.52,-3.51c1.62,-2.8 3.07,-5.94 4.34,-7.94q3.48,-5.49 6.54,-11.2c0.42,-0.8 1.17,-1.43 1.4,-2.74q0.07,-0.43 0.34,-0.78q3.16,-4.07 4.9,-9.05l9.79,-21.12q0.17,-0.38 -0.22,-0.48q-0.41,-0.12 -0.82,0.01c1.43,-1.08 3.35,-1.71 4.45,-2.91c2.31,-2.53 1.59,-7 3.37,-9.23q3.18,-3.98 3.22,1.1l0.03,4.38l-0.01,0z'
          fill='#81e2e5'
          id='svg_32'
        />
        <path
          d='m237.89,79.42q0.41,-0.13 0.82,-0.01q0.39,0.11 0.22,0.48l-9.79,21.12c-0.91,-1.86 -4.02,-1.31 -5.74,-1.78q-2.47,-0.65 -3.08,-0.69c-12.19,-0.74 -24.18,-0.99 -35.57,1.91q-3.76,0.96 -7.72,1.94q4.1,-1.91 5.42,-4.07q2,-3.26 4.08,-6.49q0.24,-0.38 0.67,-0.48l50.67,-11.93l0.02,0z'
          fill='#00c9b7'
          id='svg_31'
        />
        <path
          d='m295.9,145.14c1.58,5.82 4.37,11.81 5.53,16.97c2.36,10.48 3.45,24.54 0.01,34.8q-5.5,16.42 -18.84,28.44c-2.98,2.68 -6.42,4.83 -9.49,7.28q-2.66,2.11 -5.44,3.73a1.15,1.14 -10.2 0 1 -1.7,-1.18q0.13,-0.75 -0.13,-1.96c1.91,-3.41 3.92,-6.76 5.29,-10.33q5.83,-15.16 6.7,-31.77q1.03,-15.73 -0.8,-31.64q0.76,-3.1 -0.3,-5.61q-2.49,-18.63 -8.36,-39.86q-3.08,-11.14 -6,-18.34c-0.33,-0.82 -0.08,-1.69 -0.47,-2.64q-1.64,-4.03 -3.88,-9.12q-0.17,-0.39 0.26,-0.44l0.27,-0.02q0.67,-0.06 1.04,0.51q7.79,12.36 13.52,21.25q4.12,6.39 8.52,14.51q4.01,7.41 8.33,14.64c1.94,3.24 3.75,7.3 5.95,10.78l-0.01,0z'
          fill='#ff0b3e'
          id='svg_30'
        />
        <path
          d='m229.15,101.01q-1.75,4.98 -4.9,9.05q-0.27,0.35 -0.34,0.78c-0.23,1.31 -0.98,1.95 -1.4,2.74q-3.06,5.71 -6.54,11.2c-1.27,2.01 -2.72,5.15 -4.34,7.94c-0.66,1.16 -0.84,2.34 -1.52,3.51q-0.81,1.36 -2.22,4.25c-0.97,1.97 -2.99,4.44 -4.22,6.89q-4.06,8.1 -9.05,14.43q-0.5,0.64 -1.88,-0.07c-5.98,0.4 -8.05,-2.95 -13.04,-5.59c-7.7,-4.08 -15.37,-8.24 -23.21,-12.04c-0.82,-0.91 -1.28,-1.76 -2.04,-2.86c-1.19,-1.75 0.05,-3.13 0.22,-4.81c0.09,-0.89 0.09,-1.79 0.32,-2.65q1.18,-4.45 1.42,-9.04q0.03,-0.42 0.25,-0.78c0.78,-1.23 0.31,-2.45 1.19,-3.47q0.29,-0.33 0.29,-0.78c0.02,-3.86 1.82,-7.15 4.09,-10.33q7.3,-4.18 14.81,-6.99q3.96,-0.98 7.72,-1.94c11.39,-2.9 23.38,-2.64 35.57,-1.91q0.61,0.04 3.08,0.69c1.72,0.47 4.83,-0.08 5.74,1.78z'
          fill='#00b7b1'
          id='svg_29'
        />
        <path
          d='m399.37,121.27q4.53,16.26 5.9,34.13q-4.35,2.52 -5.94,2.9c-4.32,1.02 -10.42,0.48 -14.77,1.11q-14.51,2.09 -27.68,8.65c-4.3,2.14 -8.51,4.48 -13.06,5.75q-11.96,3.3 -25.09,4.11q-1.69,0.11 -1.1,1.69q1.49,3.99 3.51,8.46c0.86,1.91 2.32,3.67 3.47,5.5q-0.74,-0.13 -1.13,0.04l-20.59,-36.3q16.79,-7.16 31.41,-17.63l6.89,-3.74q13.44,-6.71 27.1,-11.14q14.92,-4.83 31.08,-3.53z'
          fill='#006b98'
          id='svg_28'
        />
        <path
          d='m249.33,202.94l-59.03,-0.3l4.24,-0.79a1.28,1.26 15 0 0 0.72,-0.4q1.36,-1.51 2.21,-1.89c10.94,-4.97 19.96,-12.77 27.97,-21.58q4.01,-4.4 8.17,-11.16c5.09,-8.27 10.31,-16.46 14.54,-25.26q0.56,-1.15 1.32,-0.13q0.06,0.09 -0.02,0.85c-0.42,3.91 0.02,8.92 0.03,11.6q0.06,19.65 -0.22,39.3q-0.03,1.41 0.19,4.87q0.15,2.64 -0.11,4.9l-0.01,-0.01z'
          fill='#ffebcc'
          id='svg_27'
        />
        <path
          d='m295.9,145.14l6.97,12.17l20.59,36.3c5.97,11.74 12.47,23.41 19.45,34.61c6.38,10.24 10.07,19.56 16.54,28.5a0.71,0.71 0 0 1 -0.56,1.13l-12.55,0.13a5.94,5.85 57.5 0 1 -2.64,-0.61c-4.71,-2.34 -9.25,-4.4 -14.53,-6q-3.44,-1.04 -6.85,-2.15q-31.79,-10.42 -64.05,-3.29q-3.64,0.8 -0.88,-1.7q4.68,-4.23 8.45,-11.01q0.26,1.21 0.13,1.96a1.15,1.14 -10.2 0 0 1.7,1.18q2.78,-1.62 5.44,-3.73c3.08,-2.45 6.52,-4.6 9.49,-7.28q13.33,-12.02 18.84,-28.44c3.44,-10.27 2.35,-24.32 -0.01,-34.8c-1.17,-5.16 -3.95,-11.15 -5.53,-16.97z'
          fill='#ff912a'
          id='svg_26'
        />
        <path d='m276.72,153.86q1.07,2.51 0.3,5.61l-0.27,-2.27l-0.04,-3.34l0.01,0z' fill='#ff485e' id='svg_25' />
        <path
          d='m405.27,155.4q0.79,14.01 -0.92,28.39q-15.95,-4.18 -32.9,-0.06q-10.09,2.45 -16.96,4.29c-10.41,2.79 -18.85,4.51 -28.48,6.26q-0.47,0.09 -0.79,-0.1q-0.4,-0.23 -0.61,-0.6c-1.15,-1.84 -2.61,-3.6 -3.47,-5.5q-2.03,-4.47 -3.51,-8.46q-0.59,-1.59 1.1,-1.69q13.13,-0.81 25.09,-4.11c4.55,-1.26 8.76,-3.6 13.06,-5.75q13.18,-6.56 27.68,-8.65c4.36,-0.63 10.45,-0.09 14.77,-1.11q1.59,-0.38 5.94,-2.9l0,-0.01z'
          fill='#0080b2'
          id='svg_24'
        />
        <path
          d='m276.76,157.21l0.27,2.27q1.83,15.9 0.8,31.64l-22.27,2l-0.42,-35.34l1.08,-1.43q0.25,-0.33 0.66,-0.31q6.43,0.24 11.23,0.9q4.44,0.61 8.65,0.29l0,-0.02z'
          fill='#0080b2'
          id='svg_23'
        />
        <path
          d='m404.34,183.79q-0.36,2.23 -0.74,4.74c-0.92,6.04 -2.68,12.06 -4.2,17.98q-1.97,7.69 -4.12,12.99c-4.21,10.39 -9.94,21.68 -17.13,30.95q-1.82,2.34 -4.23,5.73c-4.26,5.99 -10.53,11.68 -15.73,16.15q-6.97,0.74 -17.68,0.73q-11.52,-0.02 -23.1,-0.25q0.72,-5.1 -0.05,-9.52a0.67,0.66 84.9 0 0 -0.65,-0.56c-8.23,0.01 -16.74,-0.32 -24.99,0.44q-1.98,0.18 -3.28,0.19q-15.48,0.13 -32.08,0.08q-0.82,0 -0.82,-0.82l0.04,-69.49l22.27,-2q-0.87,16.61 -6.7,31.77c-1.37,3.57 -3.38,6.92 -5.29,10.33q-3.77,6.78 -8.45,11.01q-2.76,2.5 0.88,1.7q32.26,-7.13 64.05,3.29q3.41,1.11 6.85,2.15c5.28,1.6 9.83,3.66 14.53,6a5.94,5.85 57.5 0 0 2.64,0.61l12.55,-0.13a0.71,0.71 0 0 0 0.56,-1.13c-6.47,-8.94 -10.16,-18.26 -16.54,-28.5c-6.98,-11.2 -13.48,-22.87 -19.45,-34.61q0.39,-0.17 1.13,-0.04q0.21,0.37 0.61,0.6q0.32,0.19 0.79,0.1c9.64,-1.75 18.07,-3.47 28.48,-6.26q6.87,-1.84 16.96,-4.29q16.95,-4.12 32.9,0.06l-0.01,0z'
          fill='#001f36'
          id='svg_22'
        />
        <path
          d='m173.83,198.68q0.09,0.86 0.56,0.54l-1.6,2.83a0.65,0.65 0 0 0 0.57,0.97l16.94,-0.38l59.03,0.3l-0.24,3.48l-0.14,-2.74q-0.02,-0.33 -0.35,-0.33l-0.73,0q-0.33,0 -0.29,0.33c0.16,1.12 -0.22,2.02 -1.19,2.73c-9.06,6.68 -17.98,15.63 -28.96,18.72q-3.53,1 -4.81,1.58a1.69,1.65 -55.9 0 1 -0.79,0.16q-1.51,-0.04 -2.89,0.59q-0.35,0.16 -0.55,0.48q-0.45,0.74 -1.24,0.75q-0.43,0.01 -0.83,0.17l-0.75,0.3q-0.61,0.24 -0.01,0.52l1.4,0.65q-22.42,-0.12 -44.81,-0.04c-1.96,0 -4.1,-1.16 -5.77,1.07q-0.29,0.38 -0.07,0.81q0.36,0.72 1.13,0.03q-1.12,1.2 -1.64,-0.23l18.02,-33.28l0.01,-0.01z'
          fill='#ff485e'
          id='svg_21'
        />
        <path
          d='m249.09,206.42l-0.04,23.23a0.53,0.53 0 0 1 -0.53,0.53l-41.55,0.15l-1.4,-0.65q-0.6,-0.28 0.01,-0.52l0.75,-0.3q0.4,-0.16 0.83,-0.17q0.79,-0.02 1.24,-0.75q0.2,-0.32 0.55,-0.48q1.38,-0.64 2.89,-0.59a1.69,1.65 -55.9 0 0 0.79,-0.16q1.27,-0.58 4.81,-1.58c10.97,-3.09 19.89,-12.04 28.96,-18.72c0.97,-0.71 1.35,-1.6 1.19,-2.73q-0.04,-0.33 0.29,-0.33l0.73,0q0.33,0 0.35,0.33l0.14,2.74l-0.01,0z'
          fill='#ff0b3e'
          id='svg_20'
        />
        <path
          d='m155.81,231.96q0.52,1.43 1.64,0.23l25.45,-0.04c4.31,2.81 6.61,5.5 10.09,9.77c1.39,1.7 2.31,4.28 3.51,5.68q4.84,5.7 10.04,10.33l-19.09,0.16l-2.9,-4.72a0.4,0.4 0 0 0 -0.74,0.22q0.14,3.51 -1.72,4.12l-38.75,0.3a1.24,1.23 14.4 0 1 -1.08,-1.83l13.56,-24.24l-0.01,0.02z'
          fill='#ff5922'
          id='svg_19'
        />
        <path
          d='m182.9,232.16l65.27,-0.06a1.22,1.22 0 0 1 1.22,1.22l0.05,24.78l-42.9,-0.15q-5.2,-4.63 -10.04,-10.33c-1.2,-1.41 -2.12,-3.98 -3.51,-5.68c-3.48,-4.28 -5.77,-6.97 -10.09,-9.77l0,-0.01z'
          fill='#ff912a'
          id='svg_18'
        />
        <path
          d='m187.45,258.1l-0.14,15.07l-7.56,-0.18q1.05,0.08 1.6,-0.29q1.17,0.3 2.13,-0.33q0.37,-0.23 0.39,-0.67q0.21,-7.03 -0.39,-11.99q-0.14,-1.23 -1.4,-1.98q1.86,-0.62 1.72,-4.12a0.4,0.4 0 0 1 0.74,-0.22l2.9,4.72l0.01,-0.01z'
          fill='#ff912a'
          id='svg_17'
        />
        <path
          d='m206.54,257.94l42.9,0.15l-0.22,4.72q-0.03,0.55 -0.56,0.55l-26.75,0.1q-0.56,0 -0.9,0.43l-7.06,8.63a1.37,1.36 -70.4 0 1 -1.03,0.49l-25.6,0.16l0.14,-15.07l19.09,-0.16l-0.01,0z'
          fill='#001f36'
          id='svg_16'
        />
        <path
          d='m358.18,272.32q-11.89,10.81 -25.24,19.26l-150.01,0.09c1.17,-0.88 2.68,-0.25 4.12,-1.17c6.74,-4.36 13.87,-8.14 20.37,-12.85c3.43,-2.48 6.89,-4.78 10.2,-4.8q32,-0.22 64,-0.13c9.34,0.03 22.46,0.93 33.77,0.55q1.18,-0.04 2,-0.47q11.58,0.23 23.1,0.25q10.7,0.01 17.68,-0.73l0.01,0z'
          fill='#00b9e8'
          id='svg_15'
        />
        <path
          d='m181.36,272.71q-0.56,0.37 -1.6,0.29q-2.86,-0.19 -4.2,1.97q-6.24,10.01 -10.05,15.54a0.51,0.5 -54.4 0 0 0.11,0.68q0.33,0.27 0.96,0.46q-1.47,0.39 -3.34,-0.13q-0.13,-0.12 -0.16,-0.27q-0.1,-0.38 0.23,-0.58q1.91,-1.16 3.22,-3.01q4.28,-6.08 8.27,-12.35q2.17,-3.41 6.56,-2.59l0,-0.01z'
          fill='#00c9b7'
          id='svg_14'
        />
        <path
          d='m332.94,291.58l-26.17,15.99l-2.12,0.77l-163.81,-0.01q11.32,-7.93 22.32,-15.28q0.47,-0.3 0.06,-1.53q1.86,0.51 3.34,0.13l16.37,0.03l150.01,-0.09l0,-0.01z'
          fill='#001f36'
          id='svg_13'
        />
        <path
          d='m306.77,307.56q0.02,1.09 1.12,1.16c2.34,0.14 5.59,-0.33 8.22,-0.33q43.81,-0.05 88.55,-0.05a1.33,1.32 -90 0 1 1.32,1.33l-0.07,27.11q-2.15,-1.6 -5.12,-1.57c-8.27,0.06 -16.7,0.43 -24.81,0.32q-13.93,-0.17 -27.86,-0.05q-41.2,0.36 -82.47,0.22q0.83,-0.71 1.27,-1.48c1.86,-3.23 5.4,-4.98 8.78,-6.99c2.03,-1.2 5.3,-3.55 8.46,-5.25q9.24,-4.98 20.49,-13.63l2.12,-0.77l0,-0.02z'
          fill='#009c9b'
          id='svg_12'
        />
        <path
          d='m140.84,308.33l163.81,0.01q-11.25,8.65 -20.49,13.63c-3.16,1.7 -6.44,4.05 -8.46,5.25c-3.38,2.01 -6.91,3.77 -8.78,6.99q-0.44,0.77 -1.27,1.48l-4.2,0.01l-160.58,-0.08l39.97,-27.29z'
          fill='#00626f'
          id='svg_11'
        />
        <path
          d='m405.91,336.77l-0.1,32.62a0.85,0.84 0 0 1 -0.85,0.84l-185.68,-0.03q-1.04,-0.59 -1.84,-0.62a0.71,0.71 0 0 1 -0.53,-1.15q1.55,-1.95 3.35,-3.3q9.25,-6.96 18.81,-13.52c4.53,-3.11 10.63,-6.1 15.69,-9.73q0.06,-0.04 3.29,-2.05q2.67,-1.66 3.4,-4.14l4.2,-0.01q41.27,0.14 82.47,-0.22q13.93,-0.12 27.86,0.05c8.1,0.11 16.53,-0.26 24.81,-0.32q2.97,-0.03 5.12,1.57l0,0.01z'
          fill='#00b7b1'
          id='svg_10'
        />
        <path
          d='m100.88,335.62l160.58,0.08q-0.73,2.48 -3.4,4.14q-3.23,2.01 -3.29,2.05c-5.06,3.63 -11.16,6.62 -15.69,9.73q-9.56,6.56 -18.81,13.52q-1.8,1.35 -3.35,3.3a0.71,0.71 0 0 0 0.53,1.15q0.8,0.03 1.84,0.62l-136.27,0.06a1.53,1.52 89.5 0 1 -1.52,-1.51q-0.13,-8.46 -0.04,-16.93c0.03,-2.12 0.18,-3.13 2.04,-4.4q8.92,-6.11 17.38,-11.82l0,0.01z'
          fill='#00c9b7'
          id='svg_9'
        />
        <path
          d='m335.29,448.53l-10.61,-0.13a0.76,0.76 0 0 1 -0.75,-0.76l0.01,-15.68q0,-0.55 0.56,-0.55l32.19,-0.06a0.56,0.56 0 0 1 0.56,0.56l-0.05,41.37a0.5,0.5 0 0 1 -0.5,0.5c-7.95,0 -16.47,-0.58 -24.33,0.64c-3.45,0.54 -6.99,1.23 -10.38,1.19c-9.48,-0.13 -16.78,-2.67 -24.21,-8.1c-32.61,-23.84 -12.21,-75.31 27.25,-71.79q18.89,1.69 29.5,17.05q0.27,0.39 -0.05,0.73c-4.38,4.62 -9.91,7.94 -14.63,12.21a0.56,0.54 -40.6 0 1 -0.79,-0.05c-6.17,-7.46 -12.96,-11.53 -22.95,-8.8c-14.66,4 -17.1,23.68 -7.28,33.36c7.06,6.96 17.63,7.6 26.62,3.77a1.04,1.04 0 0 0 0.63,-0.8q0.35,-2.07 -0.12,-4.12q-0.12,-0.52 -0.65,-0.52l-0.02,-0.02z'
          fill='#ffebcc'
          id='svg_8'
          transform='matrix(1 0 0 1 0 0)'
        />
        <path
          d='m67.75,454.61a0.44,0.44 0 0 0 0.43,0.34l34.78,-0.03a0.49,0.49 0 0 1 0.49,0.49l-0.03,17.87q0,0.48 -0.48,0.48l-57,-0.02a1.03,1.03 0 0 1 -1.03,-1.04l0.07,-75.82q0,-0.36 0.36,-0.36l21.43,-0.05a0.56,0.56 0 0 1 0.56,0.56q0.06,26.81 -0.01,53.87q0,1.91 0.43,3.71z'
          fill='#ffebcc'
          id='svg_7'
          transform='matrix(1 0 0 1 0 0)'
        />
        <path
          d='m136.35,459.38a0.25,0.23 8.7 0 0 -0.23,0.16l-4.62,13.06a1.68,1.67 9.7 0 1 -1.58,1.11l-22.08,0.04a0.42,0.41 9.7 0 1 -0.39,-0.56c1.51,-4.14 3.58,-8.01 5.03,-12.17q0.78,-2.22 1.69,-4.39q10.43,-24.96 20.89,-49.91c1.17,-2.8 2.03,-6.46 3.31,-9.63a0.74,0.73 -78.6 0 1 0.67,-0.45l20.3,-0.11a0.56,0.55 78.5 0 1 0.51,0.35l32.09,76.44a0.3,0.3 0 0 1 -0.28,0.42l-23.19,0.04a0.98,0.96 79.1 0 1 -0.9,-0.62l-5.07,-13.27a0.91,0.91 -10.5 0 0 -0.85,-0.58l-25.29,0.07l-0.01,0zm4.79,-15.33a0.82,0.82 0 0 0 0.77,1.08l14.82,-0.03a0.82,0.82 0 0 0 0.77,-1.08l-7.45,-21.92a0.82,0.82 0 0 0 -1.54,0.01l-7.37,21.94z'
          fill='#ffebcc'
          id='svg_6'
          transform='matrix(1 0 0 1 0 0)'
        />
        <path
          d='m223.69,452.52a0.66,0.65 89.6 0 0 -0.65,0.66l0,20.14q0,0.44 -0.45,0.44l-19.42,-0.01a0.37,0.37 0 0 1 -0.37,-0.37l-0.04,-76.51a0.32,0.32 0 0 1 0.32,-0.32q17.63,-0.21 34.98,-0.06c8.21,0.07 14.81,1.78 20.92,7.28c12,10.8 12.49,33.37 -3.03,41.44a1.38,1.38 0 0 0 -0.55,1.95c2.75,4.55 6.92,8.82 10.23,13.04c2.4,3.08 6.92,8.19 9.99,12.74q0.61,0.91 -0.48,0.91l-23.14,0.04a3,3 0 0 1 -2.52,-1.34l-12.89,-19.28a2.17,2.16 72.3 0 0 -1.85,-0.96l-11.07,0.22l0.02,-0.01zm-0.67,-35.66l0.05,16.26a1.08,1.08 0 0 0 1.08,1.07l14.35,-0.04a8.65,8.42 -0.2 0 0 8.62,-8.45l0,-1.58a8.65,8.42 -0.2 0 0 -8.69,-8.38l-14.35,0.04a1.08,1.08 0 0 0 -1.07,1.08l0.01,0z'
          fill='#ffebcc'
          id='svg_5'
          transform='matrix(1 0 0 1 0 0)'
        />
        <path
          d='m420.09,460.27a0.91,0.91 0 0 0 -0.85,-0.64l-24.79,-0.13a1.66,1.66 0 0 0 -1.56,1.07l-4.72,12.42a1.38,1.36 -79.3 0 1 -1.27,0.88l-22.27,0.04a0.7,0.69 -78.6 0 1 -0.64,-0.97l31.41,-75.93q0.22,-0.54 0.8,-0.53l20.17,0.01a1.02,1.02 0 0 1 0.93,0.61c3.12,7.12 5.35,14.86 8.59,22.06q2.7,5.99 3.5,7.99c5.28,13.19 11.3,26.05 16.63,39.23q1.43,3.51 2.48,7.15a0.19,0.18 81.4 0 1 -0.17,0.24l-22.91,0.15a1.43,1.43 0 0 1 -1.41,-1.13c-0.94,-4.36 -2.64,-8.37 -3.93,-12.52l0.01,0zm-21.94,-16.22a0.63,0.63 0 0 0 0.6,0.82l14.63,-0.02a0.63,0.63 0 0 0 0.59,-0.83l-7.35,-22.03a0.63,0.63 0 0 0 -1.19,0l-7.28,22.05l0,0.01z'
          fill='#ffebcc'
          id='svg_4'
        />
      </g>
    </svg>
  );
};

export const LargaBlueLogo: React.FC<props> = ({ size }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 480 480' version='1.1'>
      <g className='layer'>
        <title>Layer 1</title>
        <g fill='none' id='svg_39' strokeWidth='2'>
          <path d='m156.49,144.1c7.84,3.8 15.51,7.96 23.21,12.04c4.98,2.64 7.06,6 13.04,5.59' id='svg_40' stroke='#80dbd8' />
          <path d='m192.73,161.74q0.87,1.14 0.26,2.27q-2.13,4 -6.2,11.1c-4.11,7.17 -8.33,15.86 -12.97,23.58' id='svg_41' stroke='#c0f1f2' />
          <path d='m173.83,198.68l-18.02,33.28' id='svg_42' stroke='#ffa4af' />
          <path d='m155.81,231.96l-13.56,24.24a1.24,1.23 14.4 0 0 1.08,1.83l38.75,-0.3' id='svg_43' stroke='#ffac91' />
          <path d='m182.08,257.74q1.26,0.75 1.4,1.98q0.6,4.96 0.39,11.99q-0.02,0.44 -0.39,0.67q-0.97,0.64 -2.13,0.33' id='svg_44' stroke='#ffc895' />
          <path
            d='m181.36,272.71q-4.39,-0.82 -6.56,2.59q-4,6.28 -8.27,12.35q-1.31,1.86 -3.22,3.01q-0.33,0.21 -0.23,0.58q0.04,0.15 0.16,0.27'
            id='svg_45'
            stroke='#80e4db'
          />
          <path d='m163.23,291.52q0.4,1.23 -0.06,1.53q-11,7.34 -22.32,15.28' id='svg_46' stroke='#808f9b' />
          <path d='m140.84,308.33l-39.97,27.29' id='svg_47' stroke='#80b1b7' />
          <path
            d='m100.88,335.62q-8.46,5.71 -17.38,11.82c-1.86,1.27 -2.02,2.29 -2.04,4.4q-0.1,8.46 0.04,16.93a1.53,1.52 89.5 0 0 1.52,1.51l136.27,-0.06'
            id='svg_48'
            stroke='#80e4db'
          />
          <path d='m219.28,370.21l185.68,0.03a0.85,0.84 0 0 0 0.85,-0.84l0.1,-32.62' id='svg_49' stroke='#80dbd8' />
          <path
            d='m405.91,336.77l0.07,-27.11a1.33,1.32 90 0 0 -1.32,-1.33q-44.74,0 -88.55,0.05c-2.64,0 -5.88,0.48 -8.22,0.33q-1.1,-0.06 -1.12,-1.16'
            id='svg_50'
            stroke='#80cecd'
          />
          <path d='m306.77,307.56l26.17,-15.99' id='svg_51' stroke='#808f9b' />
          <path d='m332.94,291.58q13.35,-8.45 25.24,-19.26' id='svg_52' stroke='#80dcf4' />
          <path
            d='m358.18,272.32c5.19,-4.46 11.47,-10.16 15.73,-16.15q2.41,-3.39 4.23,-5.73c7.19,-9.27 12.92,-20.56 17.13,-30.95q2.15,-5.3 4.12,-12.99c1.52,-5.92 3.28,-11.93 4.2,-17.98q0.39,-2.51 0.74,-4.74'
            id='svg_53'
            stroke='#808f9b'
          />
          <path d='m404.34,183.79q1.71,-14.38 0.92,-28.39' id='svg_54' stroke='#80c0d9' />
          <path d='m405.27,155.4q-1.37,-17.87 -5.9,-34.13' id='svg_55' stroke='#80b5cc' />
          <path
            d='m399.37,121.27q-0.62,-3.97 -1.18,-5.67q-6.45,-19.39 -17.65,-36.45c-3.68,-5.6 -7.32,-11.35 -11.52,-16.34c-9.02,-10.73 -19.08,-20.84 -30.74,-28.72q-8.49,-5.73 -11.22,-7.43c-28.86,-17.89 -64.86,-25.62 -98.37,-22.35q-2.17,0.22 -5.39,0.87c-4.48,0.91 -9.49,1.5 -13.43,2.44c-15.75,3.78 -28.23,7.86 -42.01,15.35c-5.97,3.25 -11.87,7.45 -17.72,11.41c-12.1,8.19 -22.39,19.34 -31.83,29.89q-2.85,3.19 -5.51,7.24c-4.2,6.38 -9.01,13.39 -12.26,19.27q-7.08,12.86 -11.78,26.82c-1.38,4.12 1.12,5.51 4.55,6.12q8.93,1.58 17.7,3.86c4.76,1.24 10.56,3.29 15.9,4.78q7.61,2.12 27.61,11.2q0.76,0.34 1.97,0.56'
            id='svg_56'
            stroke='#80dcf4'
          />
          <path d='m399.37,121.27q-16.16,-1.3 -31.08,3.53q-13.66,4.43 -27.1,11.14' id='svg_57' stroke='#0092c0' />
          <path
            d='m341.18,135.94q0.19,-3.6 -0.61,-6.57q-1.17,-4.34 -1.26,-5q-0.36,-2.58 -0.59,-5.17c-0.16,-1.75 -1.08,-3.42 -1.48,-5.17q-0.53,-2.35 -1.31,-3.77c-1.55,-2.82 -2.33,-4.81 -3.62,-7.1q-0.9,-1.59 -1.2,-2.3q-3.54,-8.1 -5.55,-10.34c-2.36,-2.64 -4.39,-5.53 -6.84,-8.02q-1.69,-1.72 -3.32,-3.51q-0.19,-0.21 -0.47,-0.21q-0.22,0 -0.34,-0.14q-2.35,-2.76 -5.59,-4.37q-0.23,-0.11 -0.21,-0.31q0.02,-0.21 -0.17,-0.3q-1.4,-0.65 -2.61,-1.59c-3.39,-2.63 -7.3,-4.64 -11.05,-6.69q-0.18,-0.09 -0.38,-0.1q-0.23,0 -0.47,0.01q-0.43,0.01 -0.76,-0.27q-0.62,-0.53 -1.45,-0.7q-0.4,-0.08 -0.72,-0.34q-1.67,-1.35 -3.91,-0.95'
            id='svg_58'
            stroke='#009dcd'
          />
          <path d='m287.27,63.04l-5.8,-1.86' id='svg_59' stroke='#00b8cd' />
          <path
            d='m281.47,61.18c-2.24,-1.36 -4.86,-1.54 -7.12,-2.57q-3.97,-1.8 -8.35,-2.19a1.61,1.57 -24 0 1 -0.81,-0.3q-0.32,-0.22 -0.68,-0.25q-4.36,-0.43 -8.48,-1.67'
            id='svg_60'
            stroke='#00c1d0'
          />
          <path d='m256.04,54.21l-7,-0.56' id='svg_61' stroke='#7fdceb' />
          <path
            d='m249.04,53.65q-17.09,-0.97 -33.75,4.81c-0.93,0.32 -1.81,1.36 -3.19,1.24q-0.44,-0.04 -0.85,0.09c-1.33,0.42 -2.46,0.75 -3.66,1.43q-2.89,1.63 -5.85,3.12q-1.68,0.83 -5.72,3.15c-5.67,3.24 -9.94,7.01 -14.9,12.38c-2.97,3.22 -6.84,6.28 -9.19,9.95q-5.59,8.72 -10.34,17.93q-0.65,1.27 0.65,1.65'
            id='svg_62'
            stroke='#4dcfdb'
          />
          <path
            d='m162.24,109.39c-2.27,3.17 -4.07,6.47 -4.09,10.33q0,0.45 -0.29,0.78c-0.88,1.02 -0.41,2.24 -1.19,3.47q-0.22,0.36 -0.25,0.78q-0.23,4.59 -1.42,9.04c-0.23,0.86 -0.23,1.77 -0.32,2.65c-0.17,1.68 -1.42,3.06 -0.22,4.81c0.75,1.1 1.21,1.95 2.04,2.86'
            id='svg_63'
            stroke='#00b8cd'
          />
          <path
            d='m405.27,155.4q-4.35,2.52 -5.94,2.9c-4.32,1.02 -10.42,0.48 -14.77,1.11q-14.51,2.09 -27.68,8.65c-4.3,2.14 -8.51,4.48 -13.06,5.75q-11.96,3.3 -25.09,4.11q-1.69,0.11 -1.1,1.69q1.49,3.99 3.51,8.46c0.86,1.91 2.32,3.67 3.47,5.5'
            id='svg_64'
            stroke='#0076a5'
          />
          <path d='m324.6,193.57q-0.74,-0.13 -1.13,0.04' id='svg_65' stroke='#004567' />
          <path d='m323.47,193.62l-20.59,-36.3' id='svg_66' stroke='#807e61' />
          <path d='m256.04,54.21q-0.82,8.62 -0.29,17.2' id='svg_67' stroke='#7fe4d3' />
          <path d='m255.75,71.41c-0.74,4.25 0.12,8.76 -0.03,12.29c-0.92,22.79 -0.72,44.97 -0.59,74.07' id='svg_68' stroke='#7fdbd0' />
          <path d='m255.13,157.77l0.42,35.34' id='svg_69' stroke='#7fbfd0' />
          <path
            d='m255.55,193.11l-0.04,69.49q0,0.82 0.82,0.82q16.59,0.04 32.08,-0.08q1.3,-0.01 3.28,-0.19c8.25,-0.76 16.76,-0.43 24.99,-0.44a0.67,0.66 84.9 0 1 0.65,0.56q0.77,4.42 0.05,9.52'
            id='svg_70'
            stroke='#7f8f92'
          />
          <path
            d='m317.4,272.8q-0.82,0.43 -2,0.47c-11.31,0.39 -24.43,-0.52 -33.77,-0.55q-32.01,-0.09 -64,0.13c-3.31,0.02 -6.77,2.31 -10.2,4.8c-6.5,4.71 -13.63,8.49 -20.37,12.85c-1.43,0.92 -2.94,0.3 -4.12,1.17'
            id='svg_71'
            stroke='#7fdceb'
          />
          <path d='m182.94,291.67l-16.37,-0.03' id='svg_72' stroke='#7f8f92' />
          <path
            d='m166.57,291.64q-0.63,-0.19 -0.96,-0.46a0.51,0.5 -54.4 0 1 -0.11,-0.68q3.81,-5.52 10.05,-15.54q1.34,-2.16 4.2,-1.97'
            id='svg_73'
            stroke='#7fe4d3'
          />
          <path d='m179.75,272.99l7.56,0.18' id='svg_74' stroke='#fec88c' />
          <path
            d='m187.31,273.17l25.6,-0.16a1.37,1.36 -70.4 0 0 1.03,-0.49l7.06,-8.63q0.34,-0.43 0.9,-0.43l26.75,-0.1q0.54,0 0.56,-0.55l0.22,-4.72'
            id='svg_75'
            stroke='#7f8f92'
          />
          <path d='m249.44,258.09l-0.05,-24.78a1.22,1.22 0 0 0 -1.22,-1.22l-65.27,0.06' id='svg_76' stroke='#fec88c' />
          <path d='m182.9,232.16l-25.45,0.04' id='svg_77' stroke='#feac88' />
          <path
            d='m157.45,232.19q-0.77,0.69 -1.13,-0.03q-0.22,-0.43 0.07,-0.81c1.68,-2.22 3.81,-1.07 5.77,-1.07q22.39,-0.08 44.81,0.04'
            id='svg_78'
            stroke='#fea3a6'
          />
          <path d='m206.97,230.33l41.55,-0.15a0.53,0.53 0 0 0 0.53,-0.53l0.04,-23.23' id='svg_79' stroke='#fe8596' />
          <path d='m249.09,206.42l0.24,-3.48' id='svg_80' stroke='#fea3a6' />
          <path
            d='m249.33,202.94q0.26,-2.26 0.11,-4.9q-0.22,-3.46 -0.19,-4.87q0.29,-19.65 0.22,-39.3c-0.01,-2.68 -0.45,-7.69 -0.03,-11.6q0.08,-0.76 0.02,-0.85q-0.76,-1.01 -1.32,0.13c-4.23,8.8 -9.45,17 -14.54,25.26q-4.16,6.76 -8.17,11.16c-8.01,8.81 -17.03,16.61 -27.97,21.58q-0.84,0.39 -2.21,1.89a1.28,1.26 15 0 1 -0.72,0.4l-4.24,0.79'
            id='svg_81'
            stroke='#fef5dd'
          />
          <path d='m190.31,202.64l-16.94,0.38a0.65,0.65 0 0 1 -0.57,-0.97l1.6,-2.83' id='svg_82' stroke='#fea3a6' />
          <path
            d='m174.39,199.22c4.88,-8.45 9.52,-17.49 14.21,-25.54q3.65,-6.27 7.23,-12.58c0.75,-1.33 2.6,-1.78 3.63,-2.79c5.49,-5.37 9.77,-11.34 16.06,-15.73c11,-7.69 27.35,-16.04 32.02,-29.97q1.24,-3.69 1.38,-11.19q0.27,-14.33 0.04,-28.66'
            id='svg_83'
            stroke='#bff0ea'
          />
          <path d='m249.04,53.65l-0.08,19.11' id='svg_84' stroke='#ccf2de' />
          <path
            d='m248.96,72.76l-0.03,-4.38q-0.04,-5.08 -3.22,-1.1c-1.78,2.23 -1.06,6.71 -3.37,9.23c-1.09,1.2 -3.01,1.83 -4.45,2.91'
            id='svg_85'
            stroke='#8ee4d9'
          />
          <path d='m237.89,79.42l-50.67,11.93q-0.43,0.11 -0.67,0.48q-2.08,3.23 -4.08,6.49q-1.33,2.16 -5.42,4.07' id='svg_86' stroke='#4dd7c2' />
          <path d='m177.05,102.39q-7.51,2.82 -14.81,6.99' id='svg_87' stroke='#4dcebf' />
          <path d='m341.18,135.94l-6.89,3.74' id='svg_88' stroke='#0076a5' />
          <path
            d='m287.27,63.04q10.22,5.06 15.12,9.68q9.76,9.21 12.06,11.99c9.71,11.76 14.22,22.37 17.78,37.39q1.26,5.33 2.05,17.59'
            id='svg_89'
            stroke='#009cb2'
          />
          <path d='m334.29,139.68q-14.62,10.47 -31.41,17.63' id='svg_90' stroke='#0091a5' />
          <path d='m302.87,157.32l-6.97,-12.17' id='svg_91' stroke='#80a46e' />
          <path
            d='m295.9,145.14c-2.21,-3.48 -4.02,-7.54 -5.95,-10.78q-4.32,-7.23 -8.33,-14.64q-4.4,-8.12 -8.52,-14.51q-5.73,-8.88 -13.52,-21.25q-0.37,-0.57 -1.04,-0.51l-0.27,0.02q-0.43,0.04 -0.26,0.44q2.24,5.09 3.88,9.12c0.39,0.94 0.13,1.82 0.47,2.64q2.91,7.2 6,18.34q5.87,21.23 8.36,39.86'
            id='svg_92'
            stroke='#806178'
          />
          <path d='m276.72,153.86l0.04,3.34' id='svg_93' stroke='#808088' />
          <path d='m276.76,157.21q-4.21,0.32 -8.65,-0.29q-4.81,-0.65 -11.23,-0.9q-0.41,-0.02 -0.66,0.31l-1.08,1.43' id='svg_94' stroke='#009cb2' />
          <path
            d='m281.47,61.18q-0.04,1 0.47,1.33a1.17,1.17 0 0 1 -0.01,1.95q-1.95,1.25 -3.95,1.3q-1.82,0.05 -6.05,2q-3.61,1.66 -4.72,1.68q-7.67,0.11 -9.72,0.39q-1.9,0.25 -1.73,1.59'
            id='svg_95'
            stroke='#00c0b4'
          />
          <path d='m174.39,199.22q-0.48,0.32 -0.56,-0.54' id='svg_96' stroke='#c095a2' />
          <path
            d='m192.73,161.74q1.38,0.71 1.88,0.07q4.98,-6.33 9.05,-14.43c1.24,-2.46 3.25,-4.92 4.22,-6.89q1.42,-2.89 2.22,-4.25c0.68,-1.17 0.86,-2.35 1.52,-3.51c1.62,-2.8 3.07,-5.94 4.34,-7.94q3.48,-5.49 6.54,-11.2c0.42,-0.8 1.17,-1.43 1.4,-2.74q0.07,-0.43 0.34,-0.78q3.16,-4.07 4.9,-9.05'
            id='svg_97'
            stroke='#41cdcb'
          />
          <path d='m229.15,101.01l9.79,-21.12q0.17,-0.38 -0.22,-0.48q-0.41,-0.12 -0.82,0.01' id='svg_98' stroke='#41d6ce' />
          <path
            d='m229.15,101.01c-0.91,-1.86 -4.02,-1.31 -5.74,-1.78q-2.47,-0.65 -3.08,-0.69c-12.19,-0.74 -24.18,-0.99 -35.57,1.91q-3.76,0.96 -7.72,1.94'
            id='svg_99'
            stroke='#00c0b4'
          />
          <path d='m358.18,272.32q-6.97,0.74 -17.68,0.73q-11.52,-0.02 -23.1,-0.25' id='svg_100' stroke='#006c8f' />
          <path
            d='m404.34,183.79q-15.95,-4.18 -32.9,-0.06q-10.09,2.45 -16.96,4.29c-10.41,2.79 -18.85,4.51 -28.48,6.26q-0.47,0.09 -0.79,-0.1q-0.4,-0.23 -0.61,-0.6'
            id='svg_101'
            stroke='#005074'
          />
          <path
            d='m323.47,193.62c5.97,11.74 12.47,23.41 19.45,34.61c6.38,10.24 10.07,19.56 16.54,28.5a0.71,0.71 0 0 1 -0.56,1.13l-12.55,0.13a5.94,5.85 57.5 0 1 -2.64,-0.61c-4.71,-2.34 -9.25,-4.4 -14.53,-6q-3.44,-1.04 -6.85,-2.15q-31.79,-10.42 -64.05,-3.29q-3.64,0.8 -0.88,-1.7q4.68,-4.23 8.45,-11.01'
            id='svg_102'
            stroke='#805830'
          />
          <path d='m277.82,191.11l-22.27,2' id='svg_103' stroke='#005074' />
          <path d='m187.45,258.1l-0.14,15.07' id='svg_104' stroke='#805830' />
          <path d='m179.75,272.99q1.05,0.08 1.6,-0.29' id='svg_105' stroke='#80ad71' />
          <path d='m249.33,202.94l-59.03,-0.3' id='svg_106' stroke='#ff9a95' />
          <path d='m166.57,291.64q-1.47,0.39 -3.34,-0.13' id='svg_107' stroke='#007477' />
          <path d='m249.44,258.09l-42.9,-0.15' id='svg_108' stroke='#805830' />
          <path d='m332.94,291.58l-150.01,0.09' id='svg_109' stroke='#006c8f' />
          <path d='m182.9,232.16c4.31,2.81 6.61,5.5 10.09,9.77c1.39,1.7 2.31,4.28 3.51,5.68q4.84,5.7 10.04,10.33' id='svg_110' stroke='#ff7526' />
          <path d='m206.54,257.94l-19.09,0.16' id='svg_111' stroke='#803c2c' />
          <path d='m187.45,258.1l-2.9,-4.72a0.4,0.4 0 0 0 -0.74,0.22q0.14,3.51 -1.72,4.12' id='svg_112' stroke='#ff7526' />
          <path d='m306.77,307.56l-2.12,0.77' id='svg_113' stroke='#005e69' />
          <path d='m304.66,308.34l-163.81,-0.01' id='svg_114' stroke='#004153' />
          <path
            d='m249.09,206.42l-0.14,-2.74q-0.02,-0.33 -0.35,-0.33l-0.73,0q-0.33,0 -0.29,0.33c0.16,1.12 -0.22,2.02 -1.19,2.73c-9.06,6.68 -17.98,15.63 -28.96,18.72q-3.53,1 -4.81,1.58a1.69,1.65 -55.9 0 1 -0.79,0.16q-1.51,-0.04 -2.89,0.59q-0.35,0.16 -0.55,0.48q-0.45,0.74 -1.24,0.75q-0.43,0.01 -0.83,0.17l-0.75,0.3q-0.61,0.24 -0.01,0.52l1.4,0.65'
            id='svg_115'
            stroke='#ff2a4e'
          />
          <path d='m157.45,232.19q-1.12,1.2 -1.64,-0.23' id='svg_116' stroke='#ff5140' />
          <path d='m277.03,159.48l-0.27,-2.27' id='svg_117' stroke='#806488' />
          <path
            d='m295.9,145.14c1.58,5.82 4.37,11.81 5.53,16.97c2.36,10.48 3.45,24.54 0.01,34.8q-5.5,16.42 -18.84,28.44c-2.98,2.68 -6.42,4.83 -9.49,7.28q-2.66,2.11 -5.44,3.73a1.15,1.14 -10.2 0 1 -1.7,-1.18q0.13,-0.75 -0.13,-1.96'
            id='svg_118'
            stroke='#ff4e34'
          />
          <path d='m265.84,233.22c1.91,-3.41 3.92,-6.76 5.29,-10.33q5.83,-15.16 6.7,-31.77' id='svg_119' stroke='#80153a' />
          <path d='m277.82,191.11q1.03,-15.73 -0.8,-31.64' id='svg_120' stroke='#804678' />
          <path d='m277.03,159.48q0.76,-3.1 -0.3,-5.61' id='svg_121' stroke='#ff2a4e' />
          <path d='m265.65,335.69l-4.2,0.01' id='svg_122' stroke='#008d90' />
          <path d='m261.45,335.7l-160.58,-0.08' id='svg_123' stroke='#009693' />
          <path
            d='m405.91,336.77q-2.15,-1.6 -5.12,-1.57c-8.27,0.06 -16.7,0.43 -24.81,0.32q-13.93,-0.17 -27.86,-0.05q-41.2,0.36 -82.47,0.22'
            id='svg_124'
            stroke='#00aaa6'
          />
          <path
            d='m265.65,335.69q0.83,-0.71 1.27,-1.48c1.86,-3.23 5.4,-4.98 8.78,-6.99c2.03,-1.2 5.3,-3.55 8.46,-5.25q9.24,-4.98 20.49,-13.63'
            id='svg_125'
            stroke='#007f85'
          />
          <path
            d='m219.28,370.21q-1.04,-0.59 -1.84,-0.62a0.71,0.71 0 0 1 -0.53,-1.15q1.55,-1.95 3.35,-3.3q9.25,-6.96 18.81,-13.52c4.53,-3.11 10.63,-6.1 15.69,-9.73q0.06,-0.04 3.29,-2.05q2.67,-1.66 3.4,-4.14'
            id='svg_126'
            stroke='#00c0b4'
          />
          <path
            d='m335.94,449.05q0.47,2.05 0.12,4.12a1.04,1.04 0 0 1 -0.63,0.8c-8.99,3.83 -19.56,3.18 -26.62,-3.77c-9.82,-9.68 -7.38,-29.36 7.28,-33.36c9.99,-2.73 16.78,1.34 22.95,8.8a0.56,0.54 -40.6 0 0 0.79,0.05c4.72,-4.27 10.26,-7.59 14.63,-12.21q0.32,-0.34 0.05,-0.73q-10.61,-15.37 -29.5,-17.05c-39.46,-3.51 -59.85,47.96 -27.25,71.79c7.42,5.42 14.73,7.97 24.21,8.1c3.39,0.04 6.93,-0.65 10.38,-1.19c7.86,-1.22 16.38,-0.64 24.33,-0.64a0.5,0.5 0 0 0 0.5,-0.5l0.05,-41.37a0.56,0.56 0 0 0 -0.56,-0.56l-32.19,0.06q-0.56,0 -0.56,0.55l-0.01,15.68a0.76,0.76 0 0 0 0.75,0.76l10.61,0.13q0.53,0 0.65,0.52'
            id='svg_127'
            stroke='#808f9b'
          />
          <path
            d='m67.75,454.61q-0.43,-1.8 -0.43,-3.71q0.07,-27.07 0.01,-53.87a0.56,0.56 0 0 0 -0.56,-0.56l-21.43,0.05q-0.36,0 -0.36,0.36l-0.07,75.82a1.03,1.03 0 0 0 1.03,1.04l57,0.02q0.48,0 0.48,-0.48l0.03,-17.87a0.49,0.49 0 0 0 -0.49,-0.49l-34.78,0.03a0.44,0.44 0 0 1 -0.43,-0.34'
            id='svg_128'
            stroke='#808f9b'
          />
          <path
            d='m136.35,459.38l25.29,-0.07a0.91,0.91 -10.5 0 1 0.85,0.58l5.07,13.27a0.98,0.96 79.1 0 0 0.9,0.62l23.19,-0.04a0.3,0.3 0 0 0 0.28,-0.42l-32.09,-76.44a0.56,0.55 78.5 0 0 -0.51,-0.35l-20.3,0.11a0.74,0.73 -78.6 0 0 -0.67,0.45c-1.28,3.16 -2.14,6.83 -3.31,9.63q-10.46,24.95 -20.89,49.91q-0.91,2.17 -1.69,4.39c-1.45,4.16 -3.52,8.03 -5.03,12.17a0.42,0.41 9.7 0 0 0.39,0.56l22.08,-0.04a1.68,1.67 9.7 0 0 1.58,-1.11l4.62,-13.06a0.25,0.23 8.7 0 1 0.23,-0.16'
            id='svg_129'
            stroke='#808f9b'
          />
          <path
            d='m223.69,452.52l11.07,-0.22a2.17,2.16 72.3 0 1 1.85,0.96l12.89,19.28a3,3 0 0 0 2.52,1.34l23.14,-0.04q1.08,0 0.48,-0.91c-3.07,-4.55 -7.58,-9.66 -9.99,-12.74c-3.31,-4.21 -7.48,-8.49 -10.23,-13.04a1.38,1.38 0 0 1 0.55,-1.95c15.52,-8.07 15.03,-30.63 3.03,-41.44c-6.11,-5.5 -12.71,-7.21 -20.92,-7.28q-17.35,-0.14 -34.98,0.06a0.32,0.32 0 0 0 -0.32,0.32l0.04,76.51a0.37,0.37 0 0 0 0.37,0.37l19.42,0.01q0.45,0 0.45,-0.44l0,-20.14a0.66,0.65 89.6 0 1 0.65,-0.66'
            id='svg_130'
            stroke='#808f9b'
          />
          <path
            d='m420.09,460.27c1.28,4.15 2.99,8.17 3.93,12.52a1.43,1.43 0 0 0 1.41,1.13l22.91,-0.15a0.19,0.18 81.4 0 0 0.17,-0.24q-1.06,-3.63 -2.48,-7.15c-5.33,-13.18 -11.35,-26.04 -16.63,-39.23q-0.8,-2 -3.5,-7.99c-3.24,-7.2 -5.47,-14.95 -8.59,-22.06a1.02,1.02 0 0 0 -0.93,-0.61l-20.17,-0.01q-0.57,-0.01 -0.8,0.53l-31.41,75.93a0.7,0.69 -78.6 0 0 0.64,0.97l22.27,-0.04a1.38,1.36 -79.3 0 0 1.27,-0.88l4.72,-12.42a1.66,1.66 0 0 1 1.56,-1.07l24.79,0.13a0.91,0.91 0 0 1 0.85,0.64'
            id='svg_131'
            stroke='#808f9b'
          />
          <path
            d='m141.14,444.05a0.82,0.82 0 0 0 0.77,1.08l14.82,-0.03a0.82,0.82 0 0 0 0.77,-1.08l-7.45,-21.92a0.82,0.82 0 0 0 -1.54,0.01l-7.37,21.94'
            id='svg_132'
            stroke='#808f9b'
          />
          <path
            d='m223.02,416.85l0.05,16.26a1.08,1.08 0 0 0 1.08,1.07l14.35,-0.04a8.65,8.42 -0.2 0 0 8.62,-8.45l0,-1.58a8.65,8.42 -0.2 0 0 -8.69,-8.38l-14.35,0.04a1.08,1.08 0 0 0 -1.07,1.08'
            id='svg_133'
            stroke='#808f9b'
          />
          <path
            d='m398.15,444.05a0.63,0.63 0 0 0 0.6,0.82l14.63,-0.02a0.63,0.63 0 0 0 0.59,-0.83l-7.35,-22.03a0.63,0.63 0 0 0 -1.19,0l-7.28,22.05'
            id='svg_134'
            stroke='#808f9b'
          />
        </g>
        <path
          d='m399.37,121.27q-16.16,-1.3 -31.08,3.53q-13.66,4.43 -27.1,11.14q0.19,-3.6 -0.61,-6.57q-1.17,-4.34 -1.26,-5q-0.36,-2.58 -0.59,-5.17c-0.16,-1.75 -1.08,-3.42 -1.48,-5.17q-0.53,-2.35 -1.31,-3.77c-1.55,-2.82 -2.33,-4.81 -3.62,-7.1q-0.9,-1.59 -1.2,-2.3q-3.54,-8.1 -5.55,-10.34c-2.36,-2.64 -4.39,-5.53 -6.84,-8.02q-1.69,-1.72 -3.32,-3.51q-0.19,-0.21 -0.47,-0.21q-0.22,0 -0.34,-0.14q-2.35,-2.76 -5.59,-4.37q-0.23,-0.11 -0.21,-0.31q0.02,-0.21 -0.17,-0.3q-1.4,-0.65 -2.61,-1.59c-3.39,-2.63 -7.3,-4.64 -11.05,-6.69q-0.18,-0.09 -0.38,-0.1q-0.23,0 -0.47,0.01q-0.43,0.01 -0.76,-0.27q-0.62,-0.53 -1.45,-0.7q-0.4,-0.08 -0.72,-0.34q-1.67,-1.35 -3.91,-0.95l-5.8,-1.86c-2.24,-1.36 -4.86,-1.54 -7.12,-2.57q-3.97,-1.8 -8.35,-2.19a1.61,1.57 -24 0 1 -0.81,-0.3q-0.32,-0.22 -0.68,-0.25q-4.36,-0.43 -8.48,-1.67l-7,-0.56q-17.09,-0.97 -33.75,4.81c-0.93,0.32 -1.81,1.36 -3.19,1.24q-0.44,-0.04 -0.85,0.09c-1.33,0.42 -2.46,0.75 -3.66,1.43q-2.89,1.63 -5.85,3.12q-1.68,0.83 -5.72,3.15c-5.67,3.24 -9.94,7.01 -14.9,12.38c-2.97,3.22 -6.84,6.28 -9.19,9.95q-5.59,8.72 -10.34,17.93q-0.65,1.27 0.65,1.65c-2.27,3.17 -4.07,6.47 -4.09,10.33q0,0.45 -0.29,0.78c-0.88,1.02 -0.41,2.24 -1.19,3.47q-0.22,0.36 -0.25,0.78q-0.23,4.59 -1.42,9.04c-0.23,0.86 -0.23,1.77 -0.32,2.65c-0.17,1.68 -1.42,3.06 -0.22,4.81c0.75,1.1 1.21,1.95 2.04,2.86q-1.21,-0.22 -1.97,-0.56q-20,-9.08 -27.61,-11.2c-5.33,-1.49 -11.13,-3.54 -15.9,-4.78q-8.77,-2.28 -17.7,-3.86c-3.43,-0.61 -5.94,-2 -4.55,-6.12q4.7,-13.97 11.78,-26.82c3.25,-5.88 8.06,-12.88 12.26,-19.27q2.66,-4.05 5.51,-7.24c9.44,-10.55 19.72,-21.7 31.83,-29.89c5.85,-3.96 11.74,-8.17 17.72,-11.41c13.78,-7.49 26.26,-11.57 42.01,-15.35c3.94,-0.94 8.95,-1.53 13.43,-2.44q3.22,-0.65 5.39,-0.87c33.5,-3.27 69.51,4.46 98.37,22.35q2.73,1.7 11.22,7.43c11.66,7.88 21.72,17.99 30.74,28.72c4.2,4.99 7.84,10.74 11.52,16.34q11.21,17.06 17.65,36.45q0.56,1.69 1.18,5.67l-0.01,0.02z'
          fill='#00b9e8'
          id='svg_38'
        />
        <path
          d='m249.04,53.65l-0.08,19.11l-0.03,-4.38q-0.04,-5.08 -3.22,-1.1c-1.78,2.23 -1.06,6.71 -3.37,9.23c-1.09,1.2 -3.01,1.83 -4.45,2.91l-50.67,11.93q-0.43,0.11 -0.67,0.48q-2.08,3.23 -4.08,6.49q-1.33,2.16 -5.42,4.07q-7.51,2.82 -14.81,6.99q-1.3,-0.38 -0.65,-1.65q4.75,-9.21 10.34,-17.93c2.35,-3.67 6.22,-6.73 9.19,-9.95c4.96,-5.37 9.23,-9.14 14.9,-12.38q4.04,-2.31 5.72,-3.15q2.96,-1.49 5.85,-3.12c1.2,-0.67 2.33,-1 3.66,-1.43q0.41,-0.13 0.85,-0.09c1.38,0.13 2.26,-0.91 3.19,-1.24q16.67,-5.77 33.75,-4.81l0,0.02z'
          fill='#9ae5cd'
          id='svg_37'
        />
        <path
          d='m249.04,53.65l7,0.56q-0.82,8.62 -0.29,17.2c-0.74,4.25 0.12,8.76 -0.03,12.29c-0.92,22.79 -0.72,44.97 -0.59,74.07l0.42,35.34l-0.04,69.49q0,0.82 0.82,0.82q16.59,0.04 32.08,-0.08q1.3,-0.01 3.28,-0.19c8.25,-0.76 16.76,-0.43 24.99,-0.44a0.67,0.66 84.9 0 1 0.65,0.56q0.77,4.42 0.05,9.52q-0.82,0.43 -2,0.47c-11.31,0.39 -24.43,-0.52 -33.77,-0.55q-32.01,-0.09 -64,0.13c-3.31,0.02 -6.77,2.31 -10.2,4.8c-6.5,4.71 -13.63,8.49 -20.37,12.85c-1.43,0.92 -2.94,0.3 -4.12,1.17l-16.37,-0.03q-0.63,-0.19 -0.96,-0.46a0.51,0.5 -54.4 0 1 -0.11,-0.68q3.81,-5.52 10.05,-15.54q1.34,-2.16 4.2,-1.97l7.56,0.18l25.6,-0.16a1.37,1.36 -70.4 0 0 1.03,-0.49l7.06,-8.63q0.34,-0.43 0.9,-0.43l26.75,-0.1q0.54,0 0.56,-0.55l0.22,-4.72l-0.05,-24.78a1.22,1.22 0 0 0 -1.22,-1.22l-65.27,0.06l-25.45,0.04q-0.77,0.69 -1.13,-0.03q-0.22,-0.43 0.07,-0.81c1.68,-2.22 3.81,-1.07 5.77,-1.07q22.39,-0.08 44.81,0.04l41.55,-0.15a0.53,0.53 0 0 0 0.53,-0.53l0.04,-23.23l0.24,-3.48q0.26,-2.26 0.11,-4.9q-0.22,-3.46 -0.19,-4.87q0.29,-19.65 0.22,-39.3c-0.01,-2.68 -0.45,-7.69 -0.03,-11.6q0.08,-0.76 0.02,-0.85q-0.76,-1.01 -1.32,0.13c-4.23,8.8 -9.45,17 -14.54,25.26q-4.16,6.76 -8.17,11.16c-8.01,8.81 -17.03,16.61 -27.97,21.58q-0.84,0.39 -2.21,1.89a1.28,1.26 15 0 1 -0.72,0.4l-4.24,0.79l-16.94,0.38a0.65,0.65 0 0 1 -0.57,-0.97l1.6,-2.83c4.88,-8.45 9.52,-17.49 14.21,-25.54q3.65,-6.27 7.23,-12.58c0.75,-1.33 2.6,-1.78 3.63,-2.79c5.49,-5.37 9.77,-11.34 16.06,-15.73c11,-7.69 27.35,-16.04 32.02,-29.97q1.24,-3.69 1.38,-11.19q0.27,-14.33 0.04,-28.66l0.08,-19.11l0.04,0.03z'
          fill='#fdfeee'
          id='svg_36'
        />
        <path
          d='m256.04,54.21q4.12,1.24 8.48,1.67q0.36,0.04 0.68,0.25a1.61,1.57 -24 0 0 0.81,0.3q4.38,0.39 8.35,2.19c2.26,1.03 4.88,1.21 7.12,2.57q-0.04,1 0.47,1.33a1.17,1.17 0 0 1 -0.01,1.95q-1.95,1.25 -3.95,1.3q-1.82,0.05 -6.05,2q-3.61,1.66 -4.72,1.68q-7.67,0.11 -9.72,0.39q-1.9,0.25 -1.73,1.59q-0.54,-8.58 0.29,-17.2l-0.02,-0.02z'
          fill='#00c9b7'
          id='svg_35'
        />
        <path
          d='m281.47,61.18l5.8,1.86q10.22,5.06 15.12,9.68q9.76,9.21 12.06,11.99c9.71,11.76 14.22,22.37 17.78,37.39q1.26,5.33 2.05,17.59q-14.62,10.47 -31.41,17.63l-6.97,-12.17c-2.21,-3.48 -4.02,-7.54 -5.95,-10.78q-4.32,-7.23 -8.33,-14.64q-4.4,-8.12 -8.52,-14.51q-5.73,-8.88 -13.52,-21.25q-0.37,-0.57 -1.04,-0.51l-0.27,0.02q-0.43,0.04 -0.26,0.44q2.24,5.09 3.88,9.12c0.39,0.94 0.13,1.82 0.47,2.64q2.91,7.2 6,18.34q5.87,21.23 8.36,39.86l0.04,3.34q-4.21,0.32 -8.65,-0.29q-4.81,-0.65 -11.23,-0.9q-0.41,-0.02 -0.66,0.31l-1.08,1.43c-0.13,-29.1 -0.33,-51.28 0.59,-74.07c0.14,-3.53 -0.71,-8.04 0.03,-12.29q-0.17,-1.34 1.73,-1.59q2.05,-0.28 9.72,-0.39q1.11,-0.02 4.72,-1.68q4.23,-1.95 6.05,-2q2.01,-0.05 3.95,-1.3a1.17,1.17 0 0 0 0.01,-1.95q-0.51,-0.33 -0.47,-1.33l0,0.01z'
          fill='#00b7b1'
          id='svg_34'
        />
        <path
          d='m341.18,135.94l-6.89,3.74q-0.79,-12.26 -2.05,-17.59c-3.56,-15.02 -8.07,-25.62 -17.78,-37.39q-2.3,-2.78 -12.06,-11.99q-4.9,-4.63 -15.12,-9.68q2.24,-0.4 3.91,0.95q0.31,0.26 0.72,0.34q0.83,0.17 1.45,0.7q0.33,0.28 0.76,0.27q0.23,-0.01 0.47,-0.01q0.2,0.01 0.38,0.1c3.76,2.04 7.67,4.06 11.05,6.69q1.21,0.93 2.61,1.59q0.19,0.09 0.17,0.3q-0.03,0.21 0.21,0.31q3.25,1.6 5.59,4.37q0.13,0.14 0.34,0.14q0.28,0 0.47,0.21q1.63,1.78 3.32,3.51c2.45,2.49 4.48,5.39 6.84,8.02q2.01,2.24 5.55,10.34q0.3,0.72 1.2,2.3c1.29,2.29 2.07,4.28 3.62,7.1q0.78,1.42 1.31,3.77c0.4,1.75 1.32,3.42 1.48,5.17q0.23,2.59 0.59,5.17q0.09,0.66 1.26,5q0.8,2.97 0.61,6.57l-0.01,0z'
          fill='#0080b2'
          id='svg_33'
        />
        <path
          d='m248.96,72.76q0.23,14.34 -0.04,28.66q-0.14,7.49 -1.38,11.19c-4.66,13.93 -21.01,22.28 -32.02,29.97c-6.28,4.39 -10.57,10.36 -16.06,15.73c-1.03,1 -2.88,1.46 -3.63,2.79q-3.59,6.31 -7.23,12.58c-4.69,8.05 -9.33,17.1 -14.21,25.54q-0.48,0.32 -0.56,-0.54c4.64,-7.72 8.87,-16.41 12.97,-23.58q4.06,-7.1 6.2,-11.1q0.61,-1.13 -0.26,-2.27q1.38,0.71 1.88,0.07q4.98,-6.33 9.05,-14.43c1.24,-2.46 3.25,-4.92 4.22,-6.89q1.42,-2.89 2.22,-4.25c0.68,-1.17 0.86,-2.35 1.52,-3.51c1.62,-2.8 3.07,-5.94 4.34,-7.94q3.48,-5.49 6.54,-11.2c0.42,-0.8 1.17,-1.43 1.4,-2.74q0.07,-0.43 0.34,-0.78q3.16,-4.07 4.9,-9.05l9.79,-21.12q0.17,-0.38 -0.22,-0.48q-0.41,-0.12 -0.82,0.01c1.43,-1.08 3.35,-1.71 4.45,-2.91c2.31,-2.53 1.59,-7 3.37,-9.23q3.18,-3.98 3.22,1.1l0.03,4.38l-0.01,0z'
          fill='#81e2e5'
          id='svg_32'
        />
        <path
          d='m237.89,79.42q0.41,-0.13 0.82,-0.01q0.39,0.11 0.22,0.48l-9.79,21.12c-0.91,-1.86 -4.02,-1.31 -5.74,-1.78q-2.47,-0.65 -3.08,-0.69c-12.19,-0.74 -24.18,-0.99 -35.57,1.91q-3.76,0.96 -7.72,1.94q4.1,-1.91 5.42,-4.07q2,-3.26 4.08,-6.49q0.24,-0.38 0.67,-0.48l50.67,-11.93l0.02,0z'
          fill='#00c9b7'
          id='svg_31'
        />
        <path
          d='m295.9,145.14c1.58,5.82 4.37,11.81 5.53,16.97c2.36,10.48 3.45,24.54 0.01,34.8q-5.5,16.42 -18.84,28.44c-2.98,2.68 -6.42,4.83 -9.49,7.28q-2.66,2.11 -5.44,3.73a1.15,1.14 -10.2 0 1 -1.7,-1.18q0.13,-0.75 -0.13,-1.96c1.91,-3.41 3.92,-6.76 5.29,-10.33q5.83,-15.16 6.7,-31.77q1.03,-15.73 -0.8,-31.64q0.76,-3.1 -0.3,-5.61q-2.49,-18.63 -8.36,-39.86q-3.08,-11.14 -6,-18.34c-0.33,-0.82 -0.08,-1.69 -0.47,-2.64q-1.64,-4.03 -3.88,-9.12q-0.17,-0.39 0.26,-0.44l0.27,-0.02q0.67,-0.06 1.04,0.51q7.79,12.36 13.52,21.25q4.12,6.39 8.52,14.51q4.01,7.41 8.33,14.64c1.94,3.24 3.75,7.3 5.95,10.78l-0.01,0z'
          fill='#ff0b3e'
          id='svg_30'
        />
        <path
          d='m229.15,101.01q-1.75,4.98 -4.9,9.05q-0.27,0.35 -0.34,0.78c-0.23,1.31 -0.98,1.95 -1.4,2.74q-3.06,5.71 -6.54,11.2c-1.27,2.01 -2.72,5.15 -4.34,7.94c-0.66,1.16 -0.84,2.34 -1.52,3.51q-0.81,1.36 -2.22,4.25c-0.97,1.97 -2.99,4.44 -4.22,6.89q-4.06,8.1 -9.05,14.43q-0.5,0.64 -1.88,-0.07c-5.98,0.4 -8.05,-2.95 -13.04,-5.59c-7.7,-4.08 -15.37,-8.24 -23.21,-12.04c-0.82,-0.91 -1.28,-1.76 -2.04,-2.86c-1.19,-1.75 0.05,-3.13 0.22,-4.81c0.09,-0.89 0.09,-1.79 0.32,-2.65q1.18,-4.45 1.42,-9.04q0.03,-0.42 0.25,-0.78c0.78,-1.23 0.31,-2.45 1.19,-3.47q0.29,-0.33 0.29,-0.78c0.02,-3.86 1.82,-7.15 4.09,-10.33q7.3,-4.18 14.81,-6.99q3.96,-0.98 7.72,-1.94c11.39,-2.9 23.38,-2.64 35.57,-1.91q0.61,0.04 3.08,0.69c1.72,0.47 4.83,-0.08 5.74,1.78z'
          fill='#00b7b1'
          id='svg_29'
        />
        <path
          d='m399.37,121.27q4.53,16.26 5.9,34.13q-4.35,2.52 -5.94,2.9c-4.32,1.02 -10.42,0.48 -14.77,1.11q-14.51,2.09 -27.68,8.65c-4.3,2.14 -8.51,4.48 -13.06,5.75q-11.96,3.3 -25.09,4.11q-1.69,0.11 -1.1,1.69q1.49,3.99 3.51,8.46c0.86,1.91 2.32,3.67 3.47,5.5q-0.74,-0.13 -1.13,0.04l-20.59,-36.3q16.79,-7.16 31.41,-17.63l6.89,-3.74q13.44,-6.71 27.1,-11.14q14.92,-4.83 31.08,-3.53z'
          fill='#006b98'
          id='svg_28'
        />
        <path
          d='m249.33,202.94l-59.03,-0.3l4.24,-0.79a1.28,1.26 15 0 0 0.72,-0.4q1.36,-1.51 2.21,-1.89c10.94,-4.97 19.96,-12.77 27.97,-21.58q4.01,-4.4 8.17,-11.16c5.09,-8.27 10.31,-16.46 14.54,-25.26q0.56,-1.15 1.32,-0.13q0.06,0.09 -0.02,0.85c-0.42,3.91 0.02,8.92 0.03,11.6q0.06,19.65 -0.22,39.3q-0.03,1.41 0.19,4.87q0.15,2.64 -0.11,4.9l-0.01,-0.01z'
          fill='#ffebcc'
          id='svg_27'
        />
        <path
          d='m295.9,145.14l6.97,12.17l20.59,36.3c5.97,11.74 12.47,23.41 19.45,34.61c6.38,10.24 10.07,19.56 16.54,28.5a0.71,0.71 0 0 1 -0.56,1.13l-12.55,0.13a5.94,5.85 57.5 0 1 -2.64,-0.61c-4.71,-2.34 -9.25,-4.4 -14.53,-6q-3.44,-1.04 -6.85,-2.15q-31.79,-10.42 -64.05,-3.29q-3.64,0.8 -0.88,-1.7q4.68,-4.23 8.45,-11.01q0.26,1.21 0.13,1.96a1.15,1.14 -10.2 0 0 1.7,1.18q2.78,-1.62 5.44,-3.73c3.08,-2.45 6.52,-4.6 9.49,-7.28q13.33,-12.02 18.84,-28.44c3.44,-10.27 2.35,-24.32 -0.01,-34.8c-1.17,-5.16 -3.95,-11.15 -5.53,-16.97z'
          fill='#ff912a'
          id='svg_26'
        />
        <path d='m276.72,153.86q1.07,2.51 0.3,5.61l-0.27,-2.27l-0.04,-3.34l0.01,0z' fill='#ff485e' id='svg_25' />
        <path
          d='m405.27,155.4q0.79,14.01 -0.92,28.39q-15.95,-4.18 -32.9,-0.06q-10.09,2.45 -16.96,4.29c-10.41,2.79 -18.85,4.51 -28.48,6.26q-0.47,0.09 -0.79,-0.1q-0.4,-0.23 -0.61,-0.6c-1.15,-1.84 -2.61,-3.6 -3.47,-5.5q-2.03,-4.47 -3.51,-8.46q-0.59,-1.59 1.1,-1.69q13.13,-0.81 25.09,-4.11c4.55,-1.26 8.76,-3.6 13.06,-5.75q13.18,-6.56 27.68,-8.65c4.36,-0.63 10.45,-0.09 14.77,-1.11q1.59,-0.38 5.94,-2.9l0,-0.01z'
          fill='#0080b2'
          id='svg_24'
        />
        <path
          d='m276.76,157.21l0.27,2.27q1.83,15.9 0.8,31.64l-22.27,2l-0.42,-35.34l1.08,-1.43q0.25,-0.33 0.66,-0.31q6.43,0.24 11.23,0.9q4.44,0.61 8.65,0.29l0,-0.02z'
          fill='#0080b2'
          id='svg_23'
        />
        <path
          d='m404.34,183.79q-0.36,2.23 -0.74,4.74c-0.92,6.04 -2.68,12.06 -4.2,17.98q-1.97,7.69 -4.12,12.99c-4.21,10.39 -9.94,21.68 -17.13,30.95q-1.82,2.34 -4.23,5.73c-4.26,5.99 -10.53,11.68 -15.73,16.15q-6.97,0.74 -17.68,0.73q-11.52,-0.02 -23.1,-0.25q0.72,-5.1 -0.05,-9.52a0.67,0.66 84.9 0 0 -0.65,-0.56c-8.23,0.01 -16.74,-0.32 -24.99,0.44q-1.98,0.18 -3.28,0.19q-15.48,0.13 -32.08,0.08q-0.82,0 -0.82,-0.82l0.04,-69.49l22.27,-2q-0.87,16.61 -6.7,31.77c-1.37,3.57 -3.38,6.92 -5.29,10.33q-3.77,6.78 -8.45,11.01q-2.76,2.5 0.88,1.7q32.26,-7.13 64.05,3.29q3.41,1.11 6.85,2.15c5.28,1.6 9.83,3.66 14.53,6a5.94,5.85 57.5 0 0 2.64,0.61l12.55,-0.13a0.71,0.71 0 0 0 0.56,-1.13c-6.47,-8.94 -10.16,-18.26 -16.54,-28.5c-6.98,-11.2 -13.48,-22.87 -19.45,-34.61q0.39,-0.17 1.13,-0.04q0.21,0.37 0.61,0.6q0.32,0.19 0.79,0.1c9.64,-1.75 18.07,-3.47 28.48,-6.26q6.87,-1.84 16.96,-4.29q16.95,-4.12 32.9,0.06l-0.01,0z'
          fill='#001f36'
          id='svg_22'
        />
        <path
          d='m173.83,198.68q0.09,0.86 0.56,0.54l-1.6,2.83a0.65,0.65 0 0 0 0.57,0.97l16.94,-0.38l59.03,0.3l-0.24,3.48l-0.14,-2.74q-0.02,-0.33 -0.35,-0.33l-0.73,0q-0.33,0 -0.29,0.33c0.16,1.12 -0.22,2.02 -1.19,2.73c-9.06,6.68 -17.98,15.63 -28.96,18.72q-3.53,1 -4.81,1.58a1.69,1.65 -55.9 0 1 -0.79,0.16q-1.51,-0.04 -2.89,0.59q-0.35,0.16 -0.55,0.48q-0.45,0.74 -1.24,0.75q-0.43,0.01 -0.83,0.17l-0.75,0.3q-0.61,0.24 -0.01,0.52l1.4,0.65q-22.42,-0.12 -44.81,-0.04c-1.96,0 -4.1,-1.16 -5.77,1.07q-0.29,0.38 -0.07,0.81q0.36,0.72 1.13,0.03q-1.12,1.2 -1.64,-0.23l18.02,-33.28l0.01,-0.01z'
          fill='#ff485e'
          id='svg_21'
        />
        <path
          d='m249.09,206.42l-0.04,23.23a0.53,0.53 0 0 1 -0.53,0.53l-41.55,0.15l-1.4,-0.65q-0.6,-0.28 0.01,-0.52l0.75,-0.3q0.4,-0.16 0.83,-0.17q0.79,-0.02 1.24,-0.75q0.2,-0.32 0.55,-0.48q1.38,-0.64 2.89,-0.59a1.69,1.65 -55.9 0 0 0.79,-0.16q1.27,-0.58 4.81,-1.58c10.97,-3.09 19.89,-12.04 28.96,-18.72c0.97,-0.71 1.35,-1.6 1.19,-2.73q-0.04,-0.33 0.29,-0.33l0.73,0q0.33,0 0.35,0.33l0.14,2.74l-0.01,0z'
          fill='#ff0b3e'
          id='svg_20'
        />
        <path
          d='m155.81,231.96q0.52,1.43 1.64,0.23l25.45,-0.04c4.31,2.81 6.61,5.5 10.09,9.77c1.39,1.7 2.31,4.28 3.51,5.68q4.84,5.7 10.04,10.33l-19.09,0.16l-2.9,-4.72a0.4,0.4 0 0 0 -0.74,0.22q0.14,3.51 -1.72,4.12l-38.75,0.3a1.24,1.23 14.4 0 1 -1.08,-1.83l13.56,-24.24l-0.01,0.02z'
          fill='#ff5922'
          id='svg_19'
        />
        <path
          d='m182.9,232.16l65.27,-0.06a1.22,1.22 0 0 1 1.22,1.22l0.05,24.78l-42.9,-0.15q-5.2,-4.63 -10.04,-10.33c-1.2,-1.41 -2.12,-3.98 -3.51,-5.68c-3.48,-4.28 -5.77,-6.97 -10.09,-9.77l0,-0.01z'
          fill='#ff912a'
          id='svg_18'
        />
        <path
          d='m187.45,258.1l-0.14,15.07l-7.56,-0.18q1.05,0.08 1.6,-0.29q1.17,0.3 2.13,-0.33q0.37,-0.23 0.39,-0.67q0.21,-7.03 -0.39,-11.99q-0.14,-1.23 -1.4,-1.98q1.86,-0.62 1.72,-4.12a0.4,0.4 0 0 1 0.74,-0.22l2.9,4.72l0.01,-0.01z'
          fill='#ff912a'
          id='svg_17'
        />
        <path
          d='m206.54,257.94l42.9,0.15l-0.22,4.72q-0.03,0.55 -0.56,0.55l-26.75,0.1q-0.56,0 -0.9,0.43l-7.06,8.63a1.37,1.36 -70.4 0 1 -1.03,0.49l-25.6,0.16l0.14,-15.07l19.09,-0.16l-0.01,0z'
          fill='#001f36'
          id='svg_16'
        />
        <path
          d='m358.18,272.32q-11.89,10.81 -25.24,19.26l-150.01,0.09c1.17,-0.88 2.68,-0.25 4.12,-1.17c6.74,-4.36 13.87,-8.14 20.37,-12.85c3.43,-2.48 6.89,-4.78 10.2,-4.8q32,-0.22 64,-0.13c9.34,0.03 22.46,0.93 33.77,0.55q1.18,-0.04 2,-0.47q11.58,0.23 23.1,0.25q10.7,0.01 17.68,-0.73l0.01,0z'
          fill='#00b9e8'
          id='svg_15'
        />
        <path
          d='m181.36,272.71q-0.56,0.37 -1.6,0.29q-2.86,-0.19 -4.2,1.97q-6.24,10.01 -10.05,15.54a0.51,0.5 -54.4 0 0 0.11,0.68q0.33,0.27 0.96,0.46q-1.47,0.39 -3.34,-0.13q-0.13,-0.12 -0.16,-0.27q-0.1,-0.38 0.23,-0.58q1.91,-1.16 3.22,-3.01q4.28,-6.08 8.27,-12.35q2.17,-3.41 6.56,-2.59l0,-0.01z'
          fill='#00c9b7'
          id='svg_14'
        />
        <path
          d='m332.94,291.58l-26.17,15.99l-2.12,0.77l-163.81,-0.01q11.32,-7.93 22.32,-15.28q0.47,-0.3 0.06,-1.53q1.86,0.51 3.34,0.13l16.37,0.03l150.01,-0.09l0,-0.01z'
          fill='#001f36'
          id='svg_13'
        />
        <path
          d='m306.77,307.56q0.02,1.09 1.12,1.16c2.34,0.14 5.59,-0.33 8.22,-0.33q43.81,-0.05 88.55,-0.05a1.33,1.32 -90 0 1 1.32,1.33l-0.07,27.11q-2.15,-1.6 -5.12,-1.57c-8.27,0.06 -16.7,0.43 -24.81,0.32q-13.93,-0.17 -27.86,-0.05q-41.2,0.36 -82.47,0.22q0.83,-0.71 1.27,-1.48c1.86,-3.23 5.4,-4.98 8.78,-6.99c2.03,-1.2 5.3,-3.55 8.46,-5.25q9.24,-4.98 20.49,-13.63l2.12,-0.77l0,-0.02z'
          fill='#009c9b'
          id='svg_12'
        />
        <path
          d='m140.84,308.33l163.81,0.01q-11.25,8.65 -20.49,13.63c-3.16,1.7 -6.44,4.05 -8.46,5.25c-3.38,2.01 -6.91,3.77 -8.78,6.99q-0.44,0.77 -1.27,1.48l-4.2,0.01l-160.58,-0.08l39.97,-27.29z'
          fill='#00626f'
          id='svg_11'
        />
        <path
          d='m405.91,336.77l-0.1,32.62a0.85,0.84 0 0 1 -0.85,0.84l-185.68,-0.03q-1.04,-0.59 -1.84,-0.62a0.71,0.71 0 0 1 -0.53,-1.15q1.55,-1.95 3.35,-3.3q9.25,-6.96 18.81,-13.52c4.53,-3.11 10.63,-6.1 15.69,-9.73q0.06,-0.04 3.29,-2.05q2.67,-1.66 3.4,-4.14l4.2,-0.01q41.27,0.14 82.47,-0.22q13.93,-0.12 27.86,0.05c8.1,0.11 16.53,-0.26 24.81,-0.32q2.97,-0.03 5.12,1.57l0,0.01z'
          fill='#00b7b1'
          id='svg_10'
        />
        <path
          d='m100.88,335.62l160.58,0.08q-0.73,2.48 -3.4,4.14q-3.23,2.01 -3.29,2.05c-5.06,3.63 -11.16,6.62 -15.69,9.73q-9.56,6.56 -18.81,13.52q-1.8,1.35 -3.35,3.3a0.71,0.71 0 0 0 0.53,1.15q0.8,0.03 1.84,0.62l-136.27,0.06a1.53,1.52 89.5 0 1 -1.52,-1.51q-0.13,-8.46 -0.04,-16.93c0.03,-2.12 0.18,-3.13 2.04,-4.4q8.92,-6.11 17.38,-11.82l0,0.01z'
          fill='#00c9b7'
          id='svg_9'
        />
        <path
          d='m335.29,448.53l-10.61,-0.13a0.76,0.76 0 0 1 -0.75,-0.76l0.01,-15.68q0,-0.55 0.56,-0.55l32.19,-0.06a0.56,0.56 0 0 1 0.56,0.56l-0.05,41.37a0.5,0.5 0 0 1 -0.5,0.5c-7.95,0 -16.47,-0.58 -24.33,0.64c-3.45,0.54 -6.99,1.23 -10.38,1.19c-9.48,-0.13 -16.78,-2.67 -24.21,-8.1c-32.61,-23.84 -12.21,-75.31 27.25,-71.79q18.89,1.69 29.5,17.05q0.27,0.39 -0.05,0.73c-4.38,4.62 -9.91,7.94 -14.63,12.21a0.56,0.54 -40.6 0 1 -0.79,-0.05c-6.17,-7.46 -12.96,-11.53 -22.95,-8.8c-14.66,4 -17.1,23.68 -7.28,33.36c7.06,6.96 17.63,7.6 26.62,3.77a1.04,1.04 0 0 0 0.63,-0.8q0.35,-2.07 -0.12,-4.12q-0.12,-0.52 -0.65,-0.52l-0.02,-0.02z'
          fill='#001f36'
          id='svg_8'
        />
        <path
          d='m67.75,454.61a0.44,0.44 0 0 0 0.43,0.34l34.78,-0.03a0.49,0.49 0 0 1 0.49,0.49l-0.03,17.87q0,0.48 -0.48,0.48l-57,-0.02a1.03,1.03 0 0 1 -1.03,-1.04l0.07,-75.82q0,-0.36 0.36,-0.36l21.43,-0.05a0.56,0.56 0 0 1 0.56,0.56q0.06,26.81 -0.01,53.87q0,1.91 0.43,3.71z'
          fill='#001f36'
          id='svg_7'
        />
        <path
          d='m136.35,459.38a0.25,0.23 8.7 0 0 -0.23,0.16l-4.62,13.06a1.68,1.67 9.7 0 1 -1.58,1.11l-22.08,0.04a0.42,0.41 9.7 0 1 -0.39,-0.56c1.51,-4.14 3.58,-8.01 5.03,-12.17q0.78,-2.22 1.69,-4.39q10.43,-24.96 20.89,-49.91c1.17,-2.8 2.03,-6.46 3.31,-9.63a0.74,0.73 -78.6 0 1 0.67,-0.45l20.3,-0.11a0.56,0.55 78.5 0 1 0.51,0.35l32.09,76.44a0.3,0.3 0 0 1 -0.28,0.42l-23.19,0.04a0.98,0.96 79.1 0 1 -0.9,-0.62l-5.07,-13.27a0.91,0.91 -10.5 0 0 -0.85,-0.58l-25.29,0.07l-0.01,0zm4.79,-15.33a0.82,0.82 0 0 0 0.77,1.08l14.82,-0.03a0.82,0.82 0 0 0 0.77,-1.08l-7.45,-21.92a0.82,0.82 0 0 0 -1.54,0.01l-7.37,21.94z'
          fill='#001f36'
          id='svg_6'
        />
        <path
          d='m223.69,452.52a0.66,0.65 89.6 0 0 -0.65,0.66l0,20.14q0,0.44 -0.45,0.44l-19.42,-0.01a0.37,0.37 0 0 1 -0.37,-0.37l-0.04,-76.51a0.32,0.32 0 0 1 0.32,-0.32q17.63,-0.21 34.98,-0.06c8.21,0.07 14.81,1.78 20.92,7.28c12,10.8 12.49,33.37 -3.03,41.44a1.38,1.38 0 0 0 -0.55,1.95c2.75,4.55 6.92,8.82 10.23,13.04c2.4,3.08 6.92,8.19 9.99,12.74q0.61,0.91 -0.48,0.91l-23.14,0.04a3,3 0 0 1 -2.52,-1.34l-12.89,-19.28a2.17,2.16 72.3 0 0 -1.85,-0.96l-11.07,0.22l0.02,-0.01zm-0.67,-35.66l0.05,16.26a1.08,1.08 0 0 0 1.08,1.07l14.35,-0.04a8.65,8.42 -0.2 0 0 8.62,-8.45l0,-1.58a8.65,8.42 -0.2 0 0 -8.69,-8.38l-14.35,0.04a1.08,1.08 0 0 0 -1.07,1.08l0.01,0z'
          fill='#001f36'
          id='svg_5'
        />
        <path
          d='m420.09,460.27a0.91,0.91 0 0 0 -0.85,-0.64l-24.79,-0.13a1.66,1.66 0 0 0 -1.56,1.07l-4.72,12.42a1.38,1.36 -79.3 0 1 -1.27,0.88l-22.27,0.04a0.7,0.69 -78.6 0 1 -0.64,-0.97l31.41,-75.93q0.22,-0.54 0.8,-0.53l20.17,0.01a1.02,1.02 0 0 1 0.93,0.61c3.12,7.12 5.35,14.86 8.59,22.06q2.7,5.99 3.5,7.99c5.28,13.19 11.3,26.05 16.63,39.23q1.43,3.51 2.48,7.15a0.19,0.18 81.4 0 1 -0.17,0.24l-22.91,0.15a1.43,1.43 0 0 1 -1.41,-1.13c-0.94,-4.36 -2.64,-8.37 -3.93,-12.52l0.01,0zm-21.94,-16.22a0.63,0.63 0 0 0 0.6,0.82l14.63,-0.02a0.63,0.63 0 0 0 0.59,-0.83l-7.35,-22.03a0.63,0.63 0 0 0 -1.19,0l-7.28,22.05l0,0.01z'
          fill='#001f36'
          id='svg_4'
        />
      </g>
    </svg>
  );
};
